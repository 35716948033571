import { Form, Button, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Dropdown } from '../../../../Shared/Dropdown';
import { FormInput } from '../../../../Shared/FormInput';
import DiscountRowEditor from './DiscountRowEditor';
import { CurrencyInput } from '../../../../Shared/NumericInput';
import { getTranslatedOptionsObj } from '../../../../../helpers/filters';
import './InvoiceItemEditor.scss';

export default function InvoiceItemEditor({
  category,
  itemOptions,
  item,
  notes,
  amount,
  discounts,
  index,
  onAddition,
  onChange,
  onAddDiscount,
  onChangeDiscount,
  onDeleteDiscount,
  onAdditionItemOptions,
  onChangeItemOptions,
  onDeleteInvoiceItem,
  categoryOptions,
  discountOptions,
  categoryError,
  itemError,
  discountTypeErrors,
  isSubmitDetails,
}) {
  const { t } = useTranslation();

  const [itemDropdownTouched, setItemDropdownTouched] = useState(false);

  const handleItemDropdownBlur = () => {
    setItemDropdownTouched(true);
  };

  return (
    <div>
      <Form.Group>
        <Form.Field width={4} error={categoryError}>
          <Label basic className="label-required mobile-only-label" style={{ border: 'none' }}>
            {t('Category')}
          </Label>
          <Dropdown
            data-testid={`invoice-item-category-dropdown-${index}`}
            allowAdditions
            search
            clearable
            required
            placeholder={'Select or type new'}
            name={'category'}
            value={category}
            onAddItem={(e, data) => onAddition(e, data, index)}
            onChange={(e, data) => {
              onChange(e, data, index);
            }}
            translator={t}
            options={getTranslatedOptionsObj(categoryOptions, t)}
          />
        </Form.Field>
        <Form.Field width={4} error={itemError && (isSubmitDetails || itemDropdownTouched)}>
          {itemOptions && (
            <>
              <Label basic className="label-required mobile-only-label" style={{ border: 'none' }}>
                {t('Description')}
              </Label>
              <Dropdown
                data-testid={`invoice-item-description-dropdown-${index}`}
                allowAdditions
                clearable
                search
                required
                placeholder={itemOptions.length ? t('Select or type new') : t('Select a category first')}
                name={'item'}
                value={item}
                onAddItem={(e, data) => onAdditionItemOptions(e, data, index)}
                onChange={(e, data) => onChangeItemOptions(e, data, index)}
                options={getTranslatedOptionsObj(itemOptions, t)}
                onBlur={handleItemDropdownBlur}
              />
            </>
          )}
        </Form.Field>
        <Form.Field width={5}>
          <Label className="mobile-only-label" basic style={{ border: 'none' }}>
            {t('Notes')}
          </Label>
          <FormInput
            data-testid={`invoice-item-notes-${index}`}
            value={notes}
            onChange={(e, data) => onChange(e, data, index)}
            placeholder={t('Add description (optional)')}
            name={'notes'}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Label basic className="label-required mobile-only-label" style={{ border: 'none' }}>
            {t('Amount')}
          </Label>
          <Form.Input
            data-testid={`invoice-item-amount-${index}`}
            required
            type={'text'}
            value={amount}
            onBlur={(e) => onChange(e, { name: e.target.name, value: e.target.value }, index)}
            control={CurrencyInput}
            placeholder={t('Amount')}
            name={'amount'}
          />
        </Form.Field>
        <Form.Field width={1} className="text-right mobile-margin-top">
          <Button
            data-testid={`invoice-item-delete-${index}`}
            basic
            tabIndex={index}
            href="#delete-row"
            icon="trash"
            onClick={(e) => {
              e.preventDefault();
              onDeleteInvoiceItem(index);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={6} className="mobile-margin-top">
          <a
            tabIndex={index}
            href="#add-discount"
            onClick={(e) => {
              e.preventDefault();
              onAddDiscount(index);
            }}
            data-testid={`invoice-item-add-discount-${index}`}
          >
            {t('Add Discount or Subsidy')}
          </a>
        </Form.Field>
      </Form.Group>

      {discounts.length > 0 &&
        discounts.map((discount, discountIndex) => (
          <div className="mobile-margin-top" key={discountIndex}>
            <DiscountRowEditor
              discountIndex={discountIndex}
              key={`discount${index}${discountIndex}`}
              discountOptions={discountOptions}
              discountTypeError={discountTypeErrors[discountIndex]}
              onChange={onChangeDiscount}
              onDeleteDiscount={onDeleteDiscount}
              index={index}
              {...discount}
            />
          </div>
        ))}
      <br />
    </div>
  );
}
