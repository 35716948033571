import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, isUndefined, omit } from 'lodash';
import { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Checkbox, CheckboxProps, Form, Image, InputOnChangeData, Segment, TextAreaProps } from 'semantic-ui-react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { InlineError, ShowErrors } from '../../Components/Messages';
import { Dropdown } from '../../Components/Shared/Dropdown';
import StatePicker from '../../Components/Shared/StatePicker';
import ProfilePictureUploader from '../../Components/Upload/ProfilePictureUploader';

import { userHasPermission } from '../../api/firebase/account';
import { useSelectedStudent } from '../studentsHooks';

import { FoodProgramTierEnum } from '../../common';
import { DatePicker } from '../../Components/Shared/DatePicker';
import { ethnicityOptions, raceOptions } from '../../config';
import { dateFormatter, toDateObject } from '../../helpers/dates';
import { formatFullName, phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';
import { useOrganization } from '../../hooks/useOrganizations';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { organizationAddStudent, organizationUpdateStudent } from '../studentsRedux';
import { getRandomStudentPicture } from '../studentsUtils';
import {
  StudentPersonalInformationFormValidationErrors,
  validateStudentPersonalInformationForm,
} from './studentPersonalInformation/utils/validateStudentPersonalInformationForm';

interface Props {
  onClose?: (x?: string) => undefined;
  done?: () => undefined;
  hideCancel: boolean;
}

export interface StudentPersonalInfoFormData {
  id: string;
  firstName: string;
  middleName: string;
  displayName?: string;
  lastName: string;
  nickName: string;
  birthday: string; // null; // dateFormatter(birthday)
  gender: string;
  race: string;
  ethnicity: string;
  allergies: string;
  medications: string;
  doctorName: string;
  doctorPhone: string; // phoneNumberParse(doctorPhone)
  notes: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  organization: string;
  picture: string;
  enrollmentStatus: boolean; // false
  photosAllowed: boolean; // true
  foodProgramTier: FoodProgramTierEnum; // 0 means not enrolled in food program
}

type AllStudentPersonalInformationFormErrors = StudentPersonalInformationFormValidationErrors & {
  'Unable to Update'?: string;
  'Unable to Add'?: string;
  'Unable save data'?: string;
};

const foodProgramTierOptions = [
  { text: 'food.tierDropdown.NoTier', value: FoodProgramTierEnum.TIER_0 },
  { text: 'food.tierDropdown.Tier1', value: FoodProgramTierEnum.TIER_1 },
  { text: 'food.tierDropdown.Tier2', value: FoodProgramTierEnum.TIER_2 },
];

export default function StudentPersonalInformationForm({ onClose, done, hideCancel = false }: Props) {
  const selectedStudent = useSelectedStudent();
  const currentOrganization = useOrganization();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<AllStudentPersonalInformationFormErrors>({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StudentPersonalInfoFormData>({
    ...selectedStudent,
    doctorPhone: selectedStudent.doctorPhone ? phoneNumberParse(selectedStudent.doctorPhone) : '',
  });
  const dispatch = useDispatch();
  const router = useRoutes();
  const { foodProgramFlag } = useFlags();

  const onChange = (
    e: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    { name, value, checked }: InputOnChangeData | CheckboxProps | TextAreaProps
  ) => {
    setData({
      ...data,
      [name]: !isUndefined(checked) ? checked : value,
    });
    setErrors(omit(errors, name));
  };

  const maskedOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setErrors(omit(errors, e.target.name));
  };

  const handleBirthdayChange = (e: ChangeEvent<HTMLSelectElement>, { name, value, valid }: InputOnChangeData) => {
    setData({
      ...data,
      [name]: value,
    });

    if (valid) {
      setErrors(omit(errors, name));
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const localErrors = validateStudentPersonalInformationForm(data);

    if (!isEmpty(localErrors)) {
      setErrors(localErrors);

      return;
    }

    const { id, doctorPhone, picture, birthday, displayName, ...rest } = data;

    setLoading(true);

    const formData = {
      birthday: toDateObject(birthday),
      displayName: formatFullName(rest),
      doctorPhone: phoneNumberFormat(doctorPhone),
      picture: picture ? picture : getRandomStudentPicture(),
      ...rest,
      // TODO: remove this when studentsRedux.js is converted to typescript
      combinedFamily: null,
    };

    if (currentOrganization && currentOrganization.id) {
      if (id) {
        try {
          // Update.
          dispatch(
            organizationUpdateStudent(currentOrganization.id, {
              id,
              ...formData,
              organization: currentOrganization.id,
            })
          );

          // Used to close wrapper HOC. e.g Sliding panel, modal...
          onClose?.('updated');
          setLoading(false);
          done?.();
        } catch (error: any) {
          setLoading(false);
          setErrors({ 'Unable to Update': error.message });
        }
      } else {
        // New entry.
        try {
          dispatch(
            organizationAddStudent(currentOrganization.id, {
              ...formData,
              organization: currentOrganization.id,
            })
          );
          // Update setup flags.
          setLoading(false);
          // Used to close wrapper HOC. e.g Sliding panel, modal...
          onClose?.('added');
        } catch (error: any) {
          setLoading(false);
          setErrors({ 'Unable to Add': error?.message });
        }
      }
    }
  };

  const isSetupRoute = () => {
    const isSetup = router.getActiveRoute(RouteNameEnum.SETUP);
    return isSetup;
  };

  const saveChanges = async () => {
    const { id, doctorPhone, picture, birthday, displayName, ...rest } = data;

    if (currentOrganization && currentOrganization.id) {
      setLoading(true);

      const formData = {
        id,
        birthday: toDateObject(birthday),
        displayName: formatFullName(rest),
        doctorPhone: phoneNumberFormat(doctorPhone),
        picture: picture ? picture : getRandomStudentPicture(),
        // TODO: remove this when studentsRedux.js is converted to typescript
        combinedFamily: null,
        ...rest,
      };

      try {
        await organizationUpdateStudent(currentOrganization.id, formData);
        setLoading(false);
        // Used to close wrapper HOC. e.g Sliding panel, modal...
        onClose?.('saved');
      } catch (error: any) {
        setLoading(false);
        setErrors({ 'Unable save data': error?.message });
      }
    }
  };
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <Segment basic textAlign="left">
        <ShowErrors errors={errors} />
        <Form
          id="student-personal-information-form"
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          loading={loading}
          noValidate
          data-testid="student-personal-info-form"
        >
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName} data-testid="first-name-field-error">
              <Form.Input
                required
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={onChange}
                label={t('First Name')}
                placeholder={t('First Name')}
                data-testid="first-name"
              />
              {errors.firstName && <InlineError text={errors.firstName} data-testid="first-name-error-msg" />}
            </Form.Field>

            <Form.Field width={6} data-testid="middle-name-field-error">
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={data.middleName}
                onChange={onChange}
                label={t('Middle Name')}
                placeholder={t('Middle Name')}
                data-testid="middle-name"
              />
            </Form.Field>

            <Form.Field error={!!errors.lastName} data-testid="last-name-field-error">
              <Form.Input
                required
                type="text"
                id="lastName"
                name="lastName"
                value={data.lastName}
                onChange={onChange}
                label={t('Last Name')}
                placeholder={t('Last Name')}
                data-testid="last-name"
              />
              {errors.lastName && <InlineError text={errors.lastName} data-testid="last-name-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field data-testid="nick-name-field-error">
              <Form.Input
                type="text"
                id="nickName"
                name="nickName"
                value={data.nickName}
                onChange={onChange}
                label={t('Nickname')}
                placeholder={t('Nickname')}
                data-testid="nick-name"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field width={16} data-testid="birthday-field">
              <DatePicker
                id="birthday"
                name="birthday"
                value={data.birthday ? dateFormatter(data.birthday) : ''}
                onChange={handleBirthdayChange}
                label={t('Birthday')}
                placeholder={t('Select Birthday')}
                maxDate={moment()}
                closable
                data-testid="birthday"
              ></DatePicker>
            </Form.Field>

            <Form.Field data-testid="gender-field">
              <Dropdown
                translator={t}
                id="gender"
                name="gender"
                label={t('Gender')}
                placeholder="Gender"
                selectOnBlur={false}
                value={data.gender}
                onChange={onChange}
                isForm
                options={[
                  { text: 'Male', value: 'Male' },
                  { text: 'Female', value: 'Female' },
                  { text: 'Other', value: 'Other' },
                ]}
                data-testid="gender"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field data-testid="race-field-error">
              <Dropdown
                translator={t}
                isForm
                id="race"
                name="race"
                label={t('Race')}
                placeholder="Race"
                selectOnBlur={false}
                value={data.race}
                onChange={onChange}
                options={raceOptions}
                data-testid="race"
              />
            </Form.Field>

            <Form.Field data-testid="ethnicity-field-error">
              <Dropdown
                translator={t}
                isForm
                id="ethnicity"
                name="ethnicity"
                label={t('Ethnicity')}
                placeholder="Ethnicity"
                selectOnBlur={false}
                value={data.ethnicity}
                onChange={onChange}
                options={ethnicityOptions}
                data-testid="ethnicity"
              />
            </Form.Field>
          </Form.Group>
          {foodProgramFlag && (
            <Form.Group width={8}>
              <Form.Field>
                <Dropdown
                  fluid
                  translator={t}
                  isForm
                  id="foodProgramTier"
                  name="foodProgramTier"
                  label={t('food.tierDropdownLabel')}
                  placeholder={t('food.tierDropdownPlaceholder')}
                  selectOnBlur={false}
                  value={data.foodProgramTier}
                  defaultValue={FoodProgramTierEnum.TIER_0}
                  onChange={onChange}
                  options={foodProgramTierOptions}
                  data-testid="food-program-tier"
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Field data-testid="photos-allowed-field-error">
            <Form.Radio
              toggle
              id="photosAllowed"
              name="photosAllowed"
              label={t('Photos Allowed')}
              onChange={onChange}
              checked={data.photosAllowed}
              control={Checkbox}
              data-testid="photos-allowed"
            />
          </Form.Field>

          <Form.Group widths="equal">
            {userHasPermission('can_edit_student') && data.id && currentOrganization.id && (
              <Form.Field
                label={t('Student Photo')}
                control={ProfilePictureUploader}
                uploadPath={`organizations/${currentOrganization.id}/students/${data.id}`}
                title="Uploading Student Picture"
                onUploaded={(imageUrl?: string) => {
                  setData({
                    ...data,
                    picture: imageUrl ?? '',
                  });
                  if (!isSetupRoute()) saveChanges();
                }}
                data-testid="upload-photo"
              ></Form.Field>
            )}
            {data && data.picture && (
              <Segment basic>
                <Image src={data.picture} size="medium" rounded data-testid="picture" />
              </Segment>
            )}
          </Form.Group>

          <br />
          <br />

          <Form.Field data-testid="address1-field-error">
            <Form.Input
              type="text"
              id="address1"
              name="address1"
              value={data.address1}
              onChange={onChange}
              label={t('Address 1')}
              placeholder={t('Address 1')}
              data-testid="address1"
            />
          </Form.Field>

          <Form.Field data-testid="address2-field-error">
            <Form.Input
              type="text"
              id="address2"
              name="address2"
              value={data.address2}
              onChange={onChange}
              label={t('Address 2')}
              placeholder={t('Address 2')}
              data-testid="address2"
            />
          </Form.Field>

          <Form.Field data-testid="city-field-error">
            <Form.Input
              type="text"
              id="city"
              name="city"
              value={data.city}
              onChange={onChange}
              label={t('City')}
              placeholder={t('City')}
              data-testid="city"
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field data-testid="state-field-error">
              <Form.Field
                id="state"
                name="state"
                label={t('State')}
                control={StatePicker}
                placeholder={t('State')}
                value={data.state}
                selection
                search
                onChange={onChange}
                data-testid="state"
              />
            </Form.Field>

            <Form.Field data-testid="zipcode-field-error">
              <Form.Input
                type="text"
                id="zipcode"
                name="zipcode"
                value={data.zipcode}
                onChange={onChange}
                label={t('Zip Code')}
                placeholder={t('Zip Code')}
                data-testid="zipcode"
              />
            </Form.Field>
          </Form.Group>

          <br />
          <br />

          <Form.Field data-testid="allergies-field-error">
            <Form.Input
              type="text"
              id="allergies"
              name="allergies"
              value={data.allergies}
              onChange={onChange}
              label={t('Allergies')}
              placeholder={t('If there are any allergies, add it here')}
              data-testid="allergies"
            />
          </Form.Field>

          <Form.Field data-testid="medications-field-error">
            <Form.Input
              type="text"
              id="medications"
              name="medications"
              value={data.medications}
              onChange={onChange}
              label={t('Medications')}
              placeholder={t('If there are any medications, add it here')}
              data-testid="medications"
            />
          </Form.Field>

          <Form.Group widths="equal" data-testid="doctorName-field-error">
            <Form.Field>
              <Form.Input
                type="text"
                id="doctorName"
                name="doctorName"
                value={data.doctorName}
                onChange={onChange}
                label={t('Doctor Name')}
                placeholder={t('Dr. Jane Doe')}
                data-testid="doctorName"
              />
            </Form.Field>

            <Form.Field error={!!errors.doctorPhone} data-testid="doctorPhone-field-error">
              <Form.Input
                type="text"
                id="doctorPhone"
                name="doctorPhone"
                onChange={maskedOnChange}
                label={t('Phone Number')}
                control={MaskedInput}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={data.doctorPhone}
                guide={false}
                placeholder={'(123) 456-7890'}
                data-testid="doctorPhone"
              />
              {errors.doctorPhone && <InlineError text={errors.doctorPhone} data-testid="doctorPhone-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Field data-testid="notes-field-error">
            <Form.TextArea
              type="text"
              id="notes"
              name="notes"
              value={data.notes}
              onChange={onChange}
              label={t('Notes')}
              placeholder={t('Add any relevant notes')}
              data-testid="notes"
            />
          </Form.Field>

          <br />
          <br />

          <Form.Group>
            <Form.Button primary content={t('Save')} data-testid="save-personalinfo-btn" />
            {!hideCancel && (
              <Form.Button
                basic
                content={t('Cancel')}
                onClick={(e) => {
                  if (e) e.preventDefault();
                  onClose?.();
                }}
                data-testid="cancel-personalinfo-details-btn"
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
}
