import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

// Import utils
import { dateFormatter } from '../../../helpers/dates';
import { currencyFormatter } from '../../../helpers/utils';
import useRooms from '../../../hooks/useRooms';

import { ScheduleView, useScheduledDays, useTuitionAndFees } from '../../../enrollments';
import { EnrollmentStatusEnum } from '../../../enrollments/enums';
import EnrollmentInvite from './InvitationForm';
import { useStudentEnrollment } from '../../studentsHooks';
import { WsCrossCircle, WsCheckSuccessCircle, WsPending } from '../../../icons';
import styles from '../students.module.scss';

const SHOW_STUDENT_ENROLLMENT_FEATURES = false;

export default function StudentEnrollment({ student }: { student: any }) {
  const { t } = useTranslation();
  const rooms = useRooms();
  const tuitionAndFees = useTuitionAndFees(student);
  const scheduledDays = useScheduledDays(student);
  const { enrollmentStatus, enrollmentDate, unenrollmentData } = useStudentEnrollment(student);

  const tuition = useMemo(() => {
    if (!tuitionAndFees.tuition.amount) return '--';
    return currencyFormatter(tuitionAndFees.tuition.amount / 100, { precision: 2 });
  }, [tuitionAndFees.tuition.amount]);

  const roomsLabel = useMemo(() => {
    if (!student?.rooms?.length) return '--';
    return rooms
      .filter((room: any) => student.rooms.includes(room.id))
      .map((room: any) => room.name)
      .join(', ');
  }, [student?.rooms, rooms]);

  if (!student?.id) return null;

  const COLUMN_1_WIDTH = 6;
  const COLUMN_2_WIDTH = 10;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('Room')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            <strong>{roomsLabel}</strong>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.enrollmentInfoStartDateLabel')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            <strong>{enrollmentDate ? dateFormatter(enrollmentDate) : t('No enrollment date')}</strong>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('Schedule')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            {!scheduledDays?.length && <strong>{t('students.noScheduleLabel')}</strong>}
            {!!scheduledDays?.length && (
              <ScheduleView hasTitle={false} scheduledDays={scheduledDays} className="enrollment" />
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.enrollmentInfoTuitionLabel')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            <strong>{tuition}</strong>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.enrollmentInfoFeesLabel')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            <strong>
              {!tuitionAndFees.fees.length && <div>{t('--')}</div>}
              {tuitionAndFees.fees.map((fee, index) => {
                const feeAmount =
                  fee?.amount !== undefined ? currencyFormatter(fee.amount / 100, { precision: 2 }) : '--';
                return <div key={index}>{`${feeAmount} ${fee.name}`}</div>;
              })}
            </strong>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={COLUMN_1_WIDTH}>{t('Enrollment Status')}</Grid.Column>
          <Grid.Column width={COLUMN_2_WIDTH}>
            {enrollmentStatus === EnrollmentStatusEnum.ENROLLED && (
              <span className={`${styles.successTag} ${styles.tag}`}>
                <WsCheckSuccessCircle />
                {t('Enrolled')}
              </span>
            )}
            {enrollmentStatus === EnrollmentStatusEnum.UNENROLLED && (
              <span className={`${styles.errorTag} ${styles.tag}`}>
                <WsCrossCircle />
                {t('Unenrolled')}
              </span>
            )}
            {enrollmentStatus === EnrollmentStatusEnum.PENDING && (
              <span className={`${styles.pendingTag} ${styles.tag}`}>
                <WsPending />
                {t('Pending')}
              </span>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {enrollmentStatus === EnrollmentStatusEnum.ENROLLED && unenrollmentData?.date && (
        <Grid style={{ background: '#FEF2D3', borderRadius: 10 }}>
          <Grid.Row>
            <Grid.Column>
              <strong>
                {`${student?.firstName} ${t('will be unenrolled on')} ${dateFormatter(unenrollmentData?.date)}`}
              </strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{`${t('Reason')}: ${unenrollmentData?.initiatedBy}`}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{`${t('Details')}: ${unenrollmentData?.reason}`}</Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {enrollmentStatus === EnrollmentStatusEnum.UNENROLLED && (
        <Grid style={{ background: '#FCD34D', borderRadius: 10 }}>
          <Grid.Row>
            <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.unenrollmentDate')}</Grid.Column>
            <Grid.Column width={COLUMN_2_WIDTH}>
              <strong>
                {unenrollmentData?.date ? dateFormatter(unenrollmentData.date) : t('enrollments.noUnenrollmentDate')}
              </strong>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.unenrollmentInitiatedBy')}</Grid.Column>
            <Grid.Column width={COLUMN_2_WIDTH}>
              <strong>{unenrollmentData?.initiatedBy ?? '--'}</strong>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={COLUMN_1_WIDTH}>{t('enrollments.unenrollmentReason')}</Grid.Column>
            <Grid.Column width={COLUMN_2_WIDTH}>
              <strong>{unenrollmentData?.reason ?? '--'}</strong>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {SHOW_STUDENT_ENROLLMENT_FEATURES && <EnrollmentInvite />}
    </>
  );
}
