import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Divider, Grid, Segment } from 'semantic-ui-react';

const FooterNavigation = ({ viewport = 'desktop' }) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  const showRightsReserved = () => (
    <span>
      &copy; {`${currentYear} Wonderschool.`} {t('All rights reserved.')}
    </span>
  );

  if (viewport === 'mobile') {
    return (
      <Segment vertical textAlign="center" className="footer">
        <Container fluid>
          <Divider clearing />

          <p className="no-margin">{t('Built with love by good people.')}</p>
          <p className="no-margin">{showRightsReserved()}</p>
        </Container>
      </Segment>
    );
  }

  return (
    <Segment vertical className="footer">
      <Container fluid>
        <Divider clearing />
        <Grid stackable>
          <Grid.Row className="no-padding" style={{ display: 'block' }}>
            <Grid.Column width={8} textAlign="left">
              <Segment basic>
                <p>
                  <Trans i18nKey="Built with ❤️ by good people.">
                    Built with{' '}
                    <span style={{ cursor: 'pointer' }} role="img" aria-label={t('LOVE')}>
                      ❤️
                    </span>{' '}
                    by good people.
                  </Trans>{' '}
                  {showRightsReserved()}
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Segment basic>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <span className="text-right text-gray-500">
                  {process.env.REACT_APP_GIT_TAG}({process.env.REACT_APP_GIT_SHA})
                </span>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  permissions: state.permissions,
  currentOrganization: state.organizations.currentOrganization,
});

export default withRouter(connect(mapStateToProps)(FooterNavigation));
