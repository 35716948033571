import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Checkbox, Grid, Header, Icon, List, Segment, Table } from 'semantic-ui-react';
import { WsModal } from '../../common';

// Import components.
import { updateMoxitOrganization } from '../../redux/actions/organizationActions';
import DocumentUploader from './DocumentUploader';

import { formatLocaleDate } from '../../helpers/dates';
import { CSV } from '../../helpers/mimeTypes';
import { formatFullName, humanReadableFileSize } from '../../helpers/utils';
import useRooms from '../../hooks/useRooms';
import SectionCard from '../Shared/SectionCard';
import studentsOnboardingCSV from './assets/wonderschool-onboarding-student.csv';
import ImportDataModalResult from './ImportStudentDataResult';
import StudentsCSVPlugin from './StudentsCSVPlugin';
import UppyCSV from './UppyCSV';

// This is the only way I could figure how to pass shouldInvite to the plugin.
let shouldInviteGlobal = false;
const shouldInviteCallback = () => shouldInviteGlobal;

const StudentsImporter = ({ currentOrganization, user, updateMoxitOrganization }) => {
  const [uploaded, setUploaded] = useState(false);
  const [shouldInvite, setShouldInvite] = useState(shouldInviteGlobal);
  const [isShowModalImportStudentDetails, setIsShowModalImportStudentDetails] = useState(false);
  const [resultData, setResultData] = useState(null);
  const rooms = useRooms();

  const { t } = useTranslation();

  const uppyPlugins = useMemo(() => {
    return [
      {
        plugin: UppyCSV,
        options: {
          csvTemplate: studentsOnboardingCSV,
          setResultData: setResultData,
          setIsShowModalImportStudentDetails: setIsShowModalImportStudentDetails,
        },
      },
      {
        plugin: StudentsCSVPlugin,
        options: {
          shouldInviteCallback: shouldInviteCallback,
          setResultData: setResultData,
          setIsShowModalImportStudentDetails: setIsShowModalImportStudentDetails,
          rooms: rooms,
        },
      },
    ];
  }, [rooms]);

  // Set upload path.
  const uploadPath = `organizations/${currentOrganization.id}/imports`;

  const onUploaded = async (successful) => {
    if (successful && successful.length) {
      const { uid, email } = user;

      // Set is uploaded flag to true.
      setUploaded(true);
      setIsShowModalImportStudentDetails(true);

      const imports = currentOrganization?.imports || [];

      successful.forEach((uploaded) => {
        const name = uploaded.name ? uploaded.name.split('.')[0] : t('Unknown');
        const id = uploaded?.meta?.refId?.split('.')[0];

        imports.push({
          id,
          name,
          path: uploadPath,
          type: uploaded.type,
          size: uploaded.size,
          extension: uploaded.extension,
          progress: uploaded.progress,
          meta: uploaded.meta,
          downloadUrl: uploaded.downloadUrl,
          uploadedBy: { uid, email, name: formatFullName(user, true) },
          uploadedDate: Date.now(),
          tags: [],
          status: t('processing'),
        });
      });

      try {
        await updateMoxitOrganization({ ...currentOrganization, imports });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderUploadedList = () => {
    const { imports } = currentOrganization;

    if (!imports || !imports.length) {
      return null;
    }

    return (
      <SectionCard header={t('Upload History')}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('File name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Uploaded by')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Date')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {imports.map((importItem, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <a href={importItem?.downloadUrl} alt={importItem?.name}>
                    {importItem?.name}
                  </a>{' '}
                  <div className="text-muted">
                    {humanReadableFileSize(importItem?.size)} &middot; {importItem?.meta?.refId}
                  </div>
                </Table.Cell>
                <Table.Cell>{importItem?.uploadedBy?.name}</Table.Cell>
                <Table.Cell>{formatLocaleDate(importItem?.uploadedDate)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </SectionCard>
    );
  };

  const renderPageTitle = () => {
    return (
      <Segment basic clearing>
        <Header as="h1" content={t('Import your students list')} />
      </Segment>
    );
  };

  return (
    <Segment basic>
      <Grid columns={2} stackable>
        <Grid.Row verticalAlign="middle">{renderPageTitle()}</Grid.Row>
        {!uploaded ? (
          <Grid.Row as={Segment} verticalAlign="middle">
            <Grid.Column>
              <List ordered relaxed="very">
                <List.Item>
                  <List.Content>
                    <List.Header>{t('Download a template')}</List.Header>
                    <List.Description>
                      <Trans i18nKey="To begin the importing process, download the CSV template.">
                        To begin the importing process, download the{' '}
                        <a href={studentsOnboardingCSV} alt={t('CSV Template')}>
                          CSV template
                        </a>
                        .
                      </Trans>
                    </List.Description>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <List.Header>{t("Add your student's information")}</List.Header>
                    <List.Description>
                      {t(
                        "Fill out the template with your students' information. " +
                          'If using Excel, make sure to export or save as a .csv file.'
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <List.Header>{t('Select to send parent invitation during upload')}</List.Header>
                    <List.Description>
                      {t(
                        'Select check mark if you like to bulk send parent invitations to join Wonderschool ' +
                          'and verify their accounts to access parent app during file upload. ' +
                          'You also have the option to do this later via student profile if you uncheck the checkbox.'
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>{t('Upload file')}</List.Header>
                    <List.Description as="a">{t('Upload the filled out template.')}</List.Description>
                  </List.Content>
                </List.Item>
              </List>

              <Segment basic className="text-muted">
                {t(
                  'Note: The importing process may take a few minutes to process. ' +
                    'Any duplicate students and students without rooms that do not ' +
                    'exist in the current account will be ignored.'
                )}
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment placeholder>
                <Segment padded>
                  <Checkbox
                    label={t(
                      'Bulk send parent invitation to join Wonderschool and verify accounts during this file upload process.'
                    )}
                    onChange={(_e, { checked }) => {
                      setShouldInvite(checked);
                      shouldInviteGlobal = checked;
                    }}
                    checked={shouldInvite}
                  />
                </Segment>
                <Segment basic textAlign="center">
                  <Header icon>
                    <Icon name="cloud upload" />
                    {t('Upload your students list.')}
                  </Header>
                  <DocumentUploader
                    floated="none"
                    uploadPath={uploadPath}
                    title={t('Uploading Student Documents')}
                    allowedFileTypes={[
                      '*.csv', // Added because uppy doesn't recognize *.csv during drag drop.
                      CSV,
                    ]}
                    onUploaded={onUploaded}
                    maxNumberOfFiles={1}
                    plugins={uppyPlugins}
                    isShowModalImportStudentDetails={isShowModalImportStudentDetails}
                  />
                </Segment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row as={Segment} verticalAlign="middle" centered>
            <Grid.Column width={10} textAlign="center">
              <Segment basic>
                <Header as={'h2'}>
                  <Icon circular inverted name="check" color="green" />
                </Header>
                <Header as={'h3'}>{t('Uploaded successfully')}</Header>
                <p className="text-muted">
                  <Trans
                    i18nKey="Your student roster has been uploaded successfully. Depending
                  on the size of the roster, it could take about 5 minutes for
                  the students to be added to your account. When ready, the
                  students will show up on the on the Students page."
                  >
                    Your student roster has been uploaded successfully. Depending on the size of the roster, it could
                    take about 5 minutes for the students to be added to your account. When ready, the students will
                    show up on the on the
                    {/* <Link to="/students">Students page</Link>. */}
                  </Trans>
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>{renderUploadedList()}</Grid.Row>

        <Grid.Row textAlign="center">
          <Grid.Column width={16}>
            {/*  eslint-disable-next-line i18next/no-literal-string */}
            {t('Need a hand? Send us an email to')} <a href="mailto:hello@wonderschool.com">hello@wonderschool.com</a>.
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <WsModal
        isOpen={isShowModalImportStudentDetails}
        onClose={() => setIsShowModalImportStudentDetails(false)}
        title={t('Import students report')}
      >
        <div className="flex w-96 flex-col justify-center gap-2">
          <ImportDataModalResult resultData={resultData} />
        </div>
      </WsModal>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps, { updateMoxitOrganization })(StudentsImporter);
