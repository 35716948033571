/* eslint-disable i18next/no-literal-string */
import { useTranslation } from 'react-i18next';
import { Button, Card, Input, Modal } from 'semantic-ui-react';
import './ContactsModal.scss';

export const ContactsModal = ({
  title,
  isOpen,
  onClose,
  contacts,
  onInputChange,
  isLoading,
  onClick,
  error,
  infoText,
  buttonText,
  onCardClick,
  selected,
  success,
  getSuccessMessage,
}) => {
  const { t } = useTranslation();

  const selectedContact = contacts.find(({ uid }) => uid === selected);
  const hasUid = (uid, id) => Boolean(uid) && uid !== id;

  return (
    <Modal size="small" closeIcon open={isOpen} onClose={onClose}>
      <Modal.Header>{t(title)}</Modal.Header>
      <div className="modal-body">
        <Modal.Content className="contact-list-container" scrolling>
          {contacts?.map(({ displayName, id, uid, email }) => (
            <Card
              className={`contact-card ${selected === uid ? 'card-selected' : ''} ${
                !hasUid(uid, id) ? 'card-disabled' : ''
              }`}
              key={id}
              onClick={hasUid(uid, id) ? onCardClick(uid) : null}
            >
              <span style={{ fontWeight: 'bold' }}>{displayName}</span>
              {hasUid(uid, id) ? null : (
                <span style={{ lineHeight: '20px', fontSize: '14px', color: 'gray' }}>Missing uid</span>
              )}
              <span>email: {email}</span>
              <span>contactId: {id}</span>
              {hasUid(uid, id) ? <span>userId: {uid}</span> : null}
            </Card>
          ))}
        </Modal.Content>
        <Modal.Content className="input-container">
          <Card className="input-card">
            {selected ? (
              <span style={{ marginBottom: '10px', fontSize: '16px' }}>
                Selected: <span style={{ fontWeight: 'bold' }}>{selectedContact.displayName}</span>
              </span>
            ) : null}
            <span style={{ fontSize: '14px', color: 'gray' }}>{t(infoText)}</span>
            <Input onChange={onInputChange} style={{ margin: '10px 0' }} placeholder="Stripe customerId (optional)" />
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t(buttonText)}
              loading={isLoading}
              onClick={onClick}
              style={{ fontSize: '14px' }}
              disabled={!selected}
            />
            {error ? <span style={{ marginTop: '10px', color: '#9f3a38' }}>Error: {error}</span> : null}
            {success ? <span style={{ marginTop: '10px', color: '#569c66' }}>{getSuccessMessage(success)}</span> : null}
          </Card>
        </Modal.Content>
      </div>
    </Modal>
  );
};
