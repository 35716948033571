import { Invoice } from '@wonderschool/ws-types';
import { useTranslation } from 'react-i18next';
import { Label, Popup, SemanticCOLORS } from 'semantic-ui-react';
import { InvoiceStatus as INVOICE_STATUS, invoiceStatusFormatter } from '../../../helpers/invoices';
import styles from './InvoiceStatus.module.scss';

export type InvoiceStatusProps = {
  invoice: Invoice;
  withTooltip?: boolean | undefined;
};
export default function InvoiceStatus({ invoice, withTooltip }: InvoiceStatusProps) {
  const { t } = useTranslation();
  const { color, text, popupText } = invoiceStatusFormatter(invoice, t);

  if (!color || !text) return null;

  const { status } = invoice;

  const isFailedInvoice = status === INVOICE_STATUS.FAILED || status === INVOICE_STATUS.UNCOLLECTIBLE;
  const enablePopup = popupText && (isFailedInvoice || withTooltip);

  return (
    <Popup
      on="hover"
      disabled={!enablePopup}
      content={t(popupText!)}
      trigger={<Label color={color as SemanticCOLORS} className={styles.invoiceStatus} content={t(text)} />}
    />
  );
}
