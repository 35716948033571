import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Checkbox, Container, Divider, Form, Header, Icon } from 'semantic-ui-react';
import { getkinderSystemsReports } from '../../../api/firebase/kinderSystems';
import { logError } from '../../../rollbar';
import { LoadingIndicator } from '../../Shared/BusyIndicator';
import { showErrorToast } from '../../Shared/showToast';
import AttendanceLogTable from './AttendanceLogTable';

import './KinderSystems.scss';

const KinderSystems = ({
  location,
  index,
  activeIndex,
  handleAccordionClick,
  locationsToSaveData,
  onLocationsToSaveDataChange,
}) => {
  const { t } = useTranslation();
  const defaultKinderSystemsConfig = {
    agencyID: '',
    clientSoftwareVersion: '',
    isEnabled: false,
    providerID: '',
    providerName: '',
  };
  const [loading, setLoading] = useState(false);
  const [kinderSystems, setKinderSystems] = useState(defaultKinderSystemsConfig);
  const [attendanceReports, setAttendanceReports] = useState([]);
  const [integrationCount, setIntegrationCount] = useState(0);

  useEffect(() => {
    try {
      if (!location) return;

      if (location.integrations) {
        setIntegrationCount(Object.keys(location.integrations).length);
        if (location.integrations.kinderSystems) {
          setKinderSystems(location.integrations.kinderSystems);
        }
      }
      setLoading(true);
      getkinderSystemsReports({
        organizationID: location.organization,
        locationID: location.id,
      }).then((reports) => {
        setLoading(false);
        setAttendanceReports(reports);
      });
    } catch (err) {
      setLoading(false);
      logError('Error while getting kinder systems report data: ', err);
      showErrorToast(t('Error while getting kinder systems report data'), t('Oops, something went wrong!'), err);
    }
  }, [location, t]);

  const onkinderSystemIsActiveChange = (e, { checked }) => {
    setKinderSystems({
      ...kinderSystems,
      isEnabled: checked,
    });
    onLocationsToSaveDataChange({
      ...locationsToSaveData,
      [location.id]: {
        ...location,
        integrations: {
          kinderSystems: {
            ...kinderSystems,
            isEnabled: checked,
          },
        },
      },
    });
  };

  const onTextChange = (e, { name, value }) => {
    setKinderSystems({
      ...kinderSystems,
      [name]: value,
    });
    onLocationsToSaveDataChange({
      ...locationsToSaveData,
      [location.id]: {
        ...location,
        integrations: {
          kinderSystems: {
            ...kinderSystems,
            [name]: value,
          },
        },
      },
    });
  };

  return (
    <>
      <Accordion.Title active={activeIndex === index} index={index} onClick={handleAccordionClick}>
        <Header as="h5" floated="left">
          <Icon name="dropdown" />
          {location.name}
        </Header>
        <span className="integration-count">{integrationCount}</span>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index} className="kinderConnect">
        <Form.Input
          fluid
          type="text"
          id="providerName"
          name="providerName"
          value={kinderSystems.providerName ?? ''}
          label={t('Provider Name')}
          placeholder={t('Please enter Provider Name')}
          disabled={!kinderSystems.isEnabled}
          required={kinderSystems.isEnabled}
          onChange={onTextChange}
        />
        <Divider />
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              id="providerID"
              name="providerID"
              value={kinderSystems.providerID ?? ''}
              label={t('Provider ID')}
              placeholder={t('Please enter Provider ID')}
              disabled={!kinderSystems.isEnabled}
              required={kinderSystems.isEnabled}
              onChange={onTextChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              id="agencyID"
              name="agencyID"
              value={kinderSystems.agencyID ?? ''}
              label={t('Agency ID')}
              placeholder={t('Please enter Agency ID')}
              disabled={!kinderSystems.isEnabled}
              required={kinderSystems.isEnabled}
              onChange={onTextChange}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              id="clientSoftwareVersion"
              name="clientSoftwareVersion"
              value={kinderSystems.clientSoftwareVersion ?? ''}
              label={t('Software Version')}
              placeholder={t('Please enter Software version')}
              disabled={!kinderSystems.isEnabled}
              required={kinderSystems.isEnabled}
              onChange={onTextChange}
            />
          </Form.Field>
        </Form.Group>
        <Divider />
        <Form.Group widths="equal" className="kinderConnectToggle">
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Container fluid>Connect school to KinderConnect</Container>
          <Form.Radio
            fluid
            toggle
            name="kinderSystemEnabled"
            label={kinderSystems.isEnabled ? t('Active') : t('Inactive')}
            checked={kinderSystems.isEnabled}
            onChange={onkinderSystemIsActiveChange}
            control={Checkbox}
          />
        </Form.Group>
        <Divider />
        <Container fluid>
          {/*  eslint-disable-next-line i18next/no-literal-string */}
          <Header as="h5">Attendance Log</Header>
        </Container>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <AttendanceLogTable attendanceReports={attendanceReports} setAttendanceReports={setAttendanceReports} />
        )}
      </Accordion.Content>
    </>
  );
};

export default KinderSystems;
