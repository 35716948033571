export const EARLY_DAY_CITY = [
  'new york',
  'houston',
  'dallas',
  'detroit',
  'grand rapids',
  'belleville',
  'burton',
  'canton',
  'cedar springs',
  'chesterfield',
  'clinton townshpi',
  'dearborn heights',
  'detroit',
  'eastpointe',
  'eaton rapids',
  'farmington',
  'farmington hills',
  'flushing',
  'grand blanc',
  'grand rapids',
  'inkster',
  'kentwood',
  'kentwood',
  'lansing',
  'macomb',
  'meridian carter township',
  'mount morris',
  'new baltimore',
  'oak park',
  'pontiac',
  'redford charter township',
  'rochester hills',
  'romulus',
  'roseville',
  'shelby township',
  'southfield',
  'southfield',
  'southgate',
  'st. clair shores',
  'sterling heights',
  'taylor',
  'troy',
  'warren',
  'wayne',
  'wyoming',
  'ypsilanti',
  'birmingham',
  'clawson',
  'beverly hills',
  'roseville',
  'southfield',
  'ann arbor',
  'garden city',
  'gross ile township',
  'livonia',
  'riverview',
  'southgate',
  'gross pointe woods',
  'west bloomfield township',
  'grand blanc',
  'midland',
  'dewitt',
  'east lansing',
  'grand ledge',
  'haslett',
  'holt',
  'howell',
  'okemos',
  'caledonia',
  'astoria',
  'bayside',
  'bronx',
  'brooklyn',
  'corona',
  'east elmhurst',
  'elmhurst',
  'elmsford',
  'far rockaway',
  'flushing',
  'jackson heights',
  'jamaica',
  'long island city',
  'manhattan',
  'mount vernon',
  'new york',
  'new york city',
  'ossining',
  'ozone park',
  'queens',
  'red hook',
  'ridgewood',
  'rosedale',
  'scarsdale',
  'south ozone park',
  'sunnyside',
  'woodhaven',
  'yonkers',
  'brewster',
  'chappaqua',
  'croton-on-hudson',
  'hartsdale',
  'katonah',
  'larchmont',
  'mt. kisco',
  'peekskill',
  'rye brook',
  'south salem',
  'valhalla',
  'white plains',
  'hastings-on-hudson',
  'new rochelle',
  'village of pelham',
  'middletown',
  'valley cottage',
  'woodside',
  'kew gardens',
  'richmond hills',
  'baldwin',
  'glen head',
  'roslyn',
  'neponsit',
  'medford',
  'albany',
  'allen',
  'arlington',
  'cypress',
  'dallas',
  'denton',
  'fort worth',
  'garland',
  'houston',
  'katy',
  'katy',
  'missouri city',
  'pasadena',
  'pearland',
  'plano',
  'richardson',
  'richmond',
  'spring',
  'carrollton',
  'coppell',
  'flower mound',
  'frisco',
  'irving',
  'garland',
  'grand prairie',
  'lewisville',
  'mckinney',
  'duncanville',
  'red oak',
  'mesquite',
  'azle',
  'bedford',
  'colleyville',
  'crowley',
  'euless',
  'grapevine',
  'haslett',
  'hurst',
  'mansfield',
  'midlothian',
  'southlake',
  'white settlement',
  'benbrook',
  'richland hills',
  'keller',
  'waco',
  'conroe',
  'montgomery',
  'humble',
  'kingwood',
  'magnolia',
  'tomball',
  'bellaire',
  'stafford',
  'sugar land',
  'santa fe',
  'alvin',
  'deer park',
  'fresno',
  'friendswood',
  'la marque',
  'league city',
  'texas city',
  'webster',
  'beaumont',
];

export enum REDIRECTION_LINKS {
  LEARNING_BEYOND_PAPER_HOTJAR_SURVEY = 'https://surveys.hotjar.com/87704c63-1ef3-408d-81dd-7f371f2e2512',
  EARLY_DAY_HOTJAR_SURVEY = 'https://surveys.hotjar.com/d87df4c3-1cda-4ad2-9827-38e34e555293',
  EARLY_DAY_START_HIRING = 'https://earlyday.com/centers?utm_source=wonderschool&utm_medium=website&utm_campaign=app-store&utm_content=start-hiring',
  EARLY_DAY_LEARN_MORE = 'http://earlyday.com/centers?utm_source=wonderschool&utm_medium=website&utm_campaign=app-store&utm_content=learn-more',
  LEARNING_BEYOND_PAPER_GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSeJ818Mfw3GkGmcwkYr45Jap_OzqdxcJsXj0-auMaxoT3tkgg/viewform',
  EARLY_DAY_GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLScyFuX7ijvDodZUZMwZmaDxXbPRvX3IlnXAIC56R1dfDzhqVQ/viewform',
}
