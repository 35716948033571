import type { InvoicePlan } from '@wonderschool/common-base-types';
import { Button, MainContentLayout, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { routes } from '../../../../config/routes';
import { didStripeOnboardingSucceed } from '../../../../helpers/stripe';
import { useInvoicePlans } from '../../../../hooks/useInvoicePlans';
import {
  invoiceEditSelected,
  invoiceEditSelectionCleared,
  invoicePlanNew,
} from '../../../../redux/actions/billingActions';
import EnablePaymentsModal from '../../../Billing/Invoices/EnablePaymentsModal';
import SetupPayoutRequirements from '../../../Billing/SetupPayoutRequirements';
import { selectFirstLocation } from '../../../DateTime/DateTime';
import { ShowErrors } from '../../../Messages';
import SetupPayoutBanner from '../../../SetupPayoutBanner';
import { LoadingIndicator } from '../../../Shared/BusyIndicator';
import NoDataMessageBilling from '../../../Shared/NoDataMessageBilling';
import '../Billing.scss';
import PageFilters from '../PageFilters';
import { InvoicePlanDetailSlideover } from './InvoicePlanDetailSlideover';
import { InvoicePlansDataTable } from './InvoicePlansDataTable';
import { filterInvoicePlans, isCurrentlyFiltered } from './InvoicePlansFilter';
import { Icon } from 'semantic-ui-react';
import { markRecurringPlansAsArchive } from '../../../../api/firebase/invoices';
import { isRecurringPlanElegibleForArchive } from '../../../../helpers/invoicePlans';
import SectionCard from '../../../Shared/SectionCard';

export type InvoiceFilters = {
  invoiceStatus: string;
  billingInterval: string;
  descriptionSearch: string;
  amountSearch: string;
  startDateRange: string;
  balanceDueSearch: string;
  nextInvoiceDateRange: string;
  dueDateRange: string;
  studentSearch: string;
  hideArchived: boolean;
};

const selectCurrentOrganization = createSelector(
  [(state) => state.organizations],
  (organizations) => organizations?.currentOrganization
);

export default function InvoicePlansList() {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<InvoiceFilters>({
    invoiceStatus: '',
    balanceDueSearch: '',
    billingInterval: '',
    descriptionSearch: '',
    amountSearch: '',
    startDateRange: '',
    nextInvoiceDateRange: '',
    dueDateRange: '',
    studentSearch: '',
    hideArchived: true,
  });
  const [currentInvoicePlan, setCurrentInvoicePlan] = useState<InvoicePlan | null>(null);
  const [selectedPlans, setSelectedPlans] = useState<InvoicePlan[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEditingInvoicePlan, setIsEditingInvoicePlan] = useState(false);
  const organization = useSelector(selectCurrentOrganization);
  const { invoicePlans, loading } = useInvoicePlans(organization.id);
  const isEmpty = !loading && !!invoicePlans && !invoicePlans.count && !isCurrentlyFiltered(filters);

  // we need to clear edit plan state if we land on this page
  if (!isEditingInvoicePlan) dispatch(invoiceEditSelectionCleared());

  useEffect(() => {
    if (isEditingInvoicePlan) {
      history.push(routes.billing.plans.edit);
    }
  }, [isEditingInvoicePlan, history]);

  const accountRequirements = organization?.stripe?.onboardingRequirements;
  const arePaymentsEnabled = organization?.stripe?.paymentsEnabled;

  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  const handleClickCreateNewRecurringPlan = useCallback(() => {
    dispatch(invoicePlanNew());
    history.push(routes.billing.plans.edit);
  }, [dispatch, history]);

  const refreshTable = useCallback(() => {
    // This function is temp
    // TODO: Need to update this component with listeners to firestore, like invoices list.
    setTimeout(() => {
      const hideArchived = filters.hideArchived;
      setFilters({ ...filters, hideArchived: !hideArchived });
      setFilters({ ...filters, hideArchived: hideArchived });
    }, 300);
  }, [filters]);

  const handleClickEditInvoicePlan = useCallback(
    (invoicePlan: InvoicePlan) => {
      dispatch(invoiceEditSelected(invoicePlan));
      setIsEditingInvoicePlan(true);
    },
    [dispatch]
  );

  async function onClickArchiveButton() {
    const selectedCanBeArchived = selectedPlans.filter((row) => isRecurringPlanElegibleForArchive(row));
    for (const plan of selectedCanBeArchived) {
      plan.isArchived = true;
    }
    await markRecurringPlansAsArchive(organization.id, selectedCanBeArchived);
    setSelectedPlans([]);
    refreshTable();
  }

  const data: InvoicePlan[] = useMemo(
    () => (invoicePlans ? filterInvoicePlans(invoicePlans.list, filters, timezone) : []),
    [invoicePlans, filters, timezone]
  );

  const handleCloseSlideover = useCallback(() => {
    refreshTable();
    setCurrentInvoicePlan(null);
  }, [refreshTable]);

  const handleChangeSelectedPlans = useCallback(
    (plans: InvoicePlan[]) => {
      const selectedCanBeArchived = plans.filter((row) => isRecurringPlanElegibleForArchive(row));
      setSelectedPlans(selectedCanBeArchived);
    },
    [setSelectedPlans]
  );

  return (
    <MainContentLayout
      title={t('Recurring Plans')}
      data-testid="recurring-plans-header"
      primaryAction={
        arePaymentsEnabled
          ? {
              label: t('Create New Recurring Plan'),
              onClick: handleClickCreateNewRecurringPlan,
              disabled: !didStripeOnboardingSucceed(organization),
            }
          : undefined
      }
    >
      <div className="grid grid-cols-1">
        {arePaymentsEnabled ? <PageFilters filters={filters} setFilters={setFilters} /> : null}
      </div>
      {arePaymentsEnabled ? (
        <>
          <SectionCard
            header={
              <div className="flex flex-row w-full justify-end">
                <Button
                  data-testid="archive-invoice-plan-button"
                  disabled={selectedPlans.length === 0}
                  primary
                  size={WidgetSizeEnum.SMALL}
                  onClick={onClickArchiveButton}
                >
                  <Icon name="archive" data-testid="archive-invoices-icon" />{' '}
                  {t('recurringPlanList.archiveButton.text')}
                </Button>
              </div>
            }
            className={!arePaymentsEnabled ? 'no-shadow' : ''}
          >
            {arePaymentsEnabled ? null : <EnablePaymentsModal />}
            <SetupPayoutRequirements
              organizationId={organization?.id}
              accountRequirements={accountRequirements}
              onboardingStatus={organization?.stripe?.onboardingStatus}
              onError={(error) => setErrorMessage(error)}
            />
            <SetupPayoutBanner onError={(err) => setErrorMessage(err)} />
            {errorMessage && <ShowErrors content={errorMessage} />}
            {loading && (
              <div className="loader-container">
                <LoadingIndicator />
              </div>
            )}
            {isEmpty && (
              <NoDataMessageBilling
                title={t('invoicePlansList.emptyTitle')}
                subTitle={t('invoicePlansList.emptySubtitle')}
              />
            )}

            {!loading && !isEmpty && (
              <InvoicePlansDataTable
                data={data}
                onClick={setCurrentInvoicePlan}
                onEdit={handleClickEditInvoicePlan}
                onChangeSelected={handleChangeSelectedPlans}
              />
            )}
            {!isEditingInvoicePlan && (
              <InvoicePlanDetailSlideover selectedInvoicePlan={currentInvoicePlan} onClose={handleCloseSlideover} />
            )}
          </SectionCard>
        </>
      ) : (
        <EnablePaymentsModal />
      )}
    </MainContentLayout>
  );
}
