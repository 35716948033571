import { FileDocument } from '@wonderschool/file-service-client';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useStudents } from '../../../students/studentsHooks';

import Avatar from 'react-avatar';
import { List, Modal } from 'semantic-ui-react';

import { formatFullName } from '../../../helpers/utils';

import { Button } from '@wonderschool/common-base-ui';
import styles from '../../../Components/Stats/CheckedInStudentsModal.module.scss';
import useRooms from '../../../hooks/useRooms';
import { documentTestIds } from '../../dictionary';

interface IProps {
  selectedFile?: FileDocument | null;
  isOpen: boolean;
  closeModal: () => void;
}

// TODO:
// test scroll behavior for big list of users shared with
// should overflow with ability to scroll

const MAX_NUM_OF_STUDENTS_ON_LOAD = 5;

const SharedWithModal: React.FC<IProps> = ({ selectedFile, isOpen = false, closeModal }) => {
  const {
    sharedFor,
    // updatedAt
  } = selectedFile ?? {};
  const { list } = useStudents();
  const rooms = useRooms();
  const { t } = useTranslation();
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const [studentListToRender, setStudentListToRender] = useState<any[]>([]);
  const [roomsSharedFor, setRoomsSharedFor] = useState<any[]>([]);

  useEffect(() => {
    if (sharedFor && Object.keys(sharedFor).length) {
      const sharedForKeys = Object.keys(sharedFor);

      const studentsSharedWith = list.filter((student: any) => {
        return sharedForKeys.some((key) => student.id === key.split('student-')[1]);
      });
      const roomsSharedFor = rooms.filter((room: any) => {
        return sharedForKeys.includes(`room-${room.id}`);
      });

      if (shouldShowAll) {
        setStudentListToRender(studentsSharedWith);
        setRoomsSharedFor(roomsSharedFor);
      } else {
        setStudentListToRender(studentsSharedWith.slice(0, MAX_NUM_OF_STUDENTS_ON_LOAD));
        setRoomsSharedFor(roomsSharedFor);
      }
    }
  }, [selectedFile, shouldShowAll, sharedFor, list, rooms]);

  const resetModal = () => {
    setStudentListToRender([]);
    setRoomsSharedFor([]);
    setShouldShowAll(false);
  };

  const close = () => {
    resetModal();
    closeModal();
  };

  const renderFileNotSharedContent = () => (
    <div data-testid={documentTestIds.sharedForModal.unsharedMsg}>{t('This document hasn’t been shared yet.')}</div>
  );

  const renderShowAllContent = () => {
    const numOfShares = sharedFor ? Object.keys(sharedFor).length : 0;
    if (numOfShares > MAX_NUM_OF_STUDENTS_ON_LOAD && !shouldShowAll) {
      return (
        <Button
          primary
          onClick={() => setShouldShowAll(!shouldShowAll)}
          data-testid={documentTestIds.sharedForModal.btns.showAll}
          label={t('+ {{extraStudents}} more students', {
            extraStudents: numOfShares - MAX_NUM_OF_STUDENTS_ON_LOAD,
          })}
        />
      );
    }

    return null;
  };

  const renderFileSharedContent = () => (
    <>
      {/* rooms shared for */}
      <List verticalAlign="middle" data-testid={documentTestIds.sharedForModal.roomsList}>
        {roomsSharedFor.map((room) => (
          <List.Item key={room.id}>
            <List.Icon>
              <Avatar name={room.name} round size={'28'} alt={room.name} className={styles.avatar} />
            </List.Icon>
            <List.Content>
              <List.Header>{room.name}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {/* students shared for */}
      <List verticalAlign="middle" data-testid={documentTestIds.sharedForModal.studentsList}>
        {studentListToRender.map((student: any) => (
          <List.Item key={student.id}>
            <List.Icon>
              <Avatar
                name={formatFullName(student, true)}
                round
                size={'28'}
                alt={formatFullName(student, true)}
                src={student.picture}
                className={styles.avatar}
              />
            </List.Icon>
            <List.Content>
              <List.Header>{formatFullName(student, true)}</List.Header>
              {/*
                TODO: the date updated reflects when the file was updated, not when any given entity was shared with
                thus, we need to comment this out until a file-service hook is created that can show the date of each share.
              */}
              {/* <List.Description>
                {t('Shared on')} {updatedAt && moment.utc(updatedAt).format('MM/DD/YYYY')}
              </List.Description> */}
            </List.Content>
          </List.Item>
        ))}
        {}
      </List>

      {renderShowAllContent()}

      {sharedFor && shouldShowAll && (
        <div style={{ textAlign: 'right' }} data-testid={documentTestIds.sharedForModal.btns.done}>
          <Button primary onClick={close} label={t('Done')} />
        </div>
      )}
    </>
  );

  return (
    <Modal onClose={close} open={isOpen} closeIcon size="small" closeOnDimmerClick={false}>
      <Modal.Header>{t('Shared with')}</Modal.Header>
      <Modal.Content>
        {studentListToRender.length || roomsSharedFor.length ? renderFileSharedContent() : renderFileNotSharedContent()}
      </Modal.Content>
    </Modal>
  );
};

export default SharedWithModal;
