import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { FoodProgramQueryProvider, MealSchedulingPage } from '@wonderschool/common-food-program-ui';
import { getCurrentEnvironment } from '../common/utils/environmentUtils';
import { getTitlePrefix } from '../config/env';
import { useAuthUser } from '../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../navigation';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Calendar = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.calendar);
  const { token } = useAuthUser();
  const { gotoRouteByName } = useRoutes();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Calendar') })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  if (!token) return null;

  const handleClickRecordMeal = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_RECORD_CREATE);
  };

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      <MainContentLayout>
        {renderPageHead()}
        <MealSchedulingPage onClickRecordMeal={handleClickRecordMeal} />
      </MainContentLayout>
    </FoodProgramQueryProvider>
  );
};
export default Calendar;
