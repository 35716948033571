import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Combobox, Input } from '@wonderschool/common-base-ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export function ParentsListingTable({ state, setState }) {
  const { t } = useTranslation();
  const selectRoomsList = createSelector([(state) => state.rooms], (rooms) => rooms?.list ?? []);

  const rooms = useSelector(selectRoomsList);

  const options = useMemo(() => {
    if (!rooms?.length) return [];

    const allRooms = rooms?.map((room: { id: any; name: any }) => ({
      id: room.id,
      name: room.name,
      value: room.id,
    }));

    return allRooms;
  }, [rooms]);

  return (
    <div className="parentsListingFilter px-8">
      <div className="w-full gap-2 sm:flex">
        <div className="max-[640px]:mb-4 grow">
          <Combobox
            placeholder={t('Room')}
            options={options}
            onChange={({ value }) => {
              setState((prev: { filters: any }) => ({
                ...prev,
                filters: { ...prev.filters, room: value },
              }));
            }}
            label={t('Room')}
            value={state.filters?.room || ''}
            wrapperClasses="my-auto flex-grow"
            data-testid="room-filter"
          />
        </div>
        <div className="max-[640px]:mb-4 grow">
          <Combobox
            placeholder={t('allTypesLabel')}
            options={[
              { id: 'parent', value: 'parent', name: t('Parent/Guardian') },
              { id: 'other', value: 'other', name: t('Other') },
            ]}
            onChange={({ value }) => {
              setState((prev: { filters: any }) => ({
                ...prev,
                filters: { ...prev.filters, relationship: value },
              }));
            }}
            label={t('Relationship')}
            value={state.filters?.relationship || ''}
            wrapperClasses="my-auto flex-grow"
            data-testid="relationship-filter"
          />
        </div>
        <div className="max-[640px]:mb-4 grow">
          <Combobox
            placeholder={t('noPreferenceLabel')}
            options={[
              { id: 'yes', value: 'yes', name: t('Yes') },
              { id: 'no', value: 'no', name: t('No') },
            ]}
            onChange={({ value }) => {
              setState((prev: { filters: any }) => ({
                ...prev,
                filters: { ...prev.filters, autopay: value },
              }));
            }}
            label={`${t('Autopay')} (${t('primaryPayerLabel')})`}
            value={state.filters?.autopay || ''}
            wrapperClasses="my-auto flex-grow"
            data-testid="autopay-filter"
          />
        </div>
        <div className="max-[640px]:mb-4 grow">
          <Combobox
            placeholder={t('allStatusesLabel')}
            options={[
              { id: 'verified', value: 'verified', name: t('InviteAccepted') },
              { id: 'invited', value: 'invited', name: t('Invited') },
              { id: 'new', value: 'new', name: t('Not Invited') },
            ]}
            onChange={({ value }) => {
              setState((prev: { filters: any }) => ({
                ...prev,
                filters: { ...prev.filters, invite: value },
              }));
            }}
            label={t('InviteStatus')}
            value={state.filters?.invite || ''}
            wrapperClasses="my-auto flex-grow"
            data-testid="invite-status-filter"
          />
        </div>
      </div>
      <div className="max-[640px]:mb-4 mt-2">
        <Input
          placeholder={t('searchByStudentOrContact')}
          onChange={(e) =>
            setState({
              ...state,
              searchText: e.currentTarget.value,
            })
          }
          preIcon={<MagnifyingGlassIcon className="text-grey size-6" />}
          data-testid="parents-search-input-field"
        />
      </div>
    </div>
  );
}
export default ParentsListingTable;
