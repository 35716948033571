import { TFunction } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { DefaultDateFormat } from './dates';

export enum USER_STATUS {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  INVOICE_SENT = 'invoiceSent',
  NOTIFICATION_SENT = 'notificationSent',
  PAST_DUE = 'pastDue',
  PROCESSING = 'processing',
  CHARGE_FAILED = 'chargeFailed',
  TRANSFERRING = 'transferring',
  TRANSFER_FAILED = 'transferFailed',
  PAID = 'paid',
  PAID_MANUALLY = 'paidManually',
  PAYOUT_REVERSED = 'payoutReversed',
  PAYOUT_REVERSED_FAILED = 'payoutReversedFailed',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum USER_STATUS_DESCRIPTION {
  PAID_MANUALLY = 'userStatusDescription.paidManually',
}

const userInvoiceStatusMap: { [key: string]: { text: string; color?: string } } = {
  [USER_STATUS.SCHEDULED]: {
    text: 'Scheduled',
    color: 'grey',
  },
  [USER_STATUS.INVOICE_SENT]: {
    text: 'status.invoiceSent',
    color: 'blue',
  },
  [USER_STATUS.NOTIFICATION_SENT]: {
    text: 'status.notificationSent',
    color: 'blue',
  },
  [USER_STATUS.PAST_DUE]: {
    text: 'status.pastDue',
    color: 'red',
  },
  [USER_STATUS.PROCESSING]: {
    text: 'Processing',
    color: 'yellow',
  },
  [USER_STATUS.CHARGE_FAILED]: {
    text: 'status.chargeFailed',
    color: 'red',
  },
  [USER_STATUS.TRANSFERRING]: {
    text: 'Transferring',
    color: 'teal',
  },
  [USER_STATUS.TRANSFER_FAILED]: {
    text: 'status.transferFailed',
    color: 'red',
  },
  [USER_STATUS.PAYOUT_REVERSED]: {
    text: 'status.payoutReversed',
    color: 'red',
  },
  [USER_STATUS.PAYOUT_REVERSED_FAILED]: {
    text: 'status.payoutReversedFailed',
    color: 'red',
  },
  [USER_STATUS.PAID]: {
    text: 'status.deposited',
    color: 'green',
  },
  [USER_STATUS.PAID_MANUALLY]: {
    text: 'status.paidManually',
    color: 'green',
  },
};

export const getFailurePopupText = (t: TFunction, failureMessage?: string, nextPaymentAttempt?: string) => {
  if (!failureMessage && !nextPaymentAttempt) return t('payments.pleaseContactWS');

  const nextAttemptString = nextPaymentAttempt
    ? t('ccms.nextAttemptAt', { date: moment(nextPaymentAttempt).format(DefaultDateFormat) })
    : '';

  const failureMessageString = failureMessage ? t('payments.chargeFailedMessage', { message: failureMessage }) : '';

  return `${failureMessageString} ${nextAttemptString}`.trim();
};

export type UserStatusFormatter = {
  text: string;
  color?: string;
  popupText: string;
  userStatus: string;
};
/**
 * This userStatus formatter will eventually replace the rest of the invoiceStatusFormatter
 * as userStatus gets implemented.
 */
export const userStatusFormatter = (
  {
    userStatus,
    userStatusDescription,
    failureMessage,
    nextPaymentAttempt,
    payments,
  }: {
    userStatus: string;
    userStatusDescription: string;
    failureMessage?: string;
    nextPaymentAttempt?: string;
    payments?: { status: string; createdAt: number }[];
  },
  t: TFunction
): UserStatusFormatter => {
  const format = { ...userInvoiceStatusMap[userStatus] };

  // sort payments by created date
  if (payments && payments.length > 1) {
    payments.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }

  // if user status is deposited but payment hasn't settled yet, return quick deposited status
  if (
    userStatus === USER_STATUS.PAID &&
    payments &&
    payments.length > 0 &&
    payments[payments.length - 1].status !== 'paid'
  ) {
    format.text = 'status.quickDeposited';
    format.color = 'green';
    userStatusDescription = 'userStatusDescription.quickDeposited';
  }

  if (userStatus === USER_STATUS.CHARGE_FAILED)
    return {
      ...format,
      popupText: getFailurePopupText(t, failureMessage, nextPaymentAttempt),
      userStatus,
    };

  return {
    ...format,
    popupText: userStatusDescription,
    userStatus,
  };
};
