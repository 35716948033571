import CN from 'classnames';
import { useCallback } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Card, Header, Icon, Label, Loader } from 'semantic-ui-react';

// Import utils
import { formatFullName } from '../../helpers/utils';

import ContactStatusLabel from '../../contacts/ContactStatusLabel';
import { CONTACT_STATUS } from '../../contacts/enums';

import { useOrganization } from '../../hooks/useOrganizations';
import { WsLock } from '../../icons';
import cardStyles from '../Shared/CardHelpers/CardStyles.module.scss';
import styles from './StaffEditCard.module.scss';

const getStaffEmail = (staffContact) =>
  staffContact.email && staffContact.email.indexOf('moxit_') !== -1 ? '' : staffContact.email;

const getStaffName = (staffContact) => staffContact.displayName || formatFullName(staffContact, true);

const getStaffPhotoUrlMaybe = (staffContact) => staffContact.photoURL || staffContact.picture || undefined; // returns undefined if not present, including empty string

const renderStaffPin = (staffContact) =>
  staffContact.loginPin ? (
    <Label content={`PIN: ${staffContact.loginPin}`} active />
  ) : (
    <Label content={'No Pin'} basic />
  );

const StaffEditCard = ({ staffContact, onClick, onClickSend }) => {
  const { t } = useTranslation();
  const organization = useOrganization();

  const onClickSendLocal = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClickSend) onClickSend(staffContact);
    },
    [onClickSend, staffContact]
  );

  const renderSendLink = useCallback(() => {
    return (
      <>
        {staffContact.status === CONTACT_STATUS.invited && (
          <span className="pseudo-link" onClick={onClickSendLocal} data-testid="resend-invite-btn">
            <Icon name="redo" size="small" />
            {t('Resend Invitation')}
          </span>
        )}
        {staffContact.status === CONTACT_STATUS.new && !!staffContact.email && !staffContact?.uid && (
          <span className="pseudo-link" onClick={onClickSendLocal} disabled data-testid="send-invite-btn">
            <Icon name="paper plane outline" size="small" />
            {t('Send Invitation')}
          </span>
        )}
        {staffContact.status === CONTACT_STATUS.new && !staffContact.email && (
          <span data-testid="send-invite-btn">
            <Icon name="paper plane outline" size="small" />
            {t('Send Invitation')}
          </span>
        )}
        {staffContact.status === CONTACT_STATUS.inviting && (
          <span data-testid="sending-invitation">
            <Loader size="mini" active inline />
            {t('Sending Invitation...')}
          </span>
        )}
      </>
    );
  }, [onClickSendLocal, staffContact.email, staffContact.status, staffContact?.uid, t]);

  return (
    <Card className="shadow" key={staffContact.id} onClick={onClick} data-testid="staff-card">
      <Card.Content textAlign="center" className={styles.cardWidthFix}>
        <Card.Header>
          <Avatar
            name={getStaffName(staffContact)}
            src={getStaffPhotoUrlMaybe(staffContact)}
            round
            size={80}
            textSizeRatio={3}
          />
        </Card.Header>
      </Card.Content>
      <Card.Content textAlign="center" className={CN(styles.cardContentBorderless, styles.cardWidthFix)}>
        <Card.Header>
          <Header as="h3" className={cardStyles.textTruncate}>
            {getStaffName(staffContact)}
            {staffContact.uid === organization.createdBy && (
              <Header as="h5" className={styles.customspacer}>
                {/*  eslint-disable-next-line i18next/no-literal-string */}
                <WsLock /> Account Holder
              </Header>
            )}
          </Header>
        </Card.Header>
        <Card.Meta className={cardStyles.textTruncate}>{getStaffEmail(staffContact)}</Card.Meta>
        <Card.Description>{renderStaffPin(staffContact)}</Card.Description>
        <Card.Description>{renderSendLink()}</Card.Description>
      </Card.Content>

      <Card.Content extra textAlign="center" className={styles.cardWidthFix}>
        <ContactStatusLabel contact={staffContact} />
      </Card.Content>
    </Card>
  );
};

export default StaffEditCard;
