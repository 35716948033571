import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Label, Popup } from 'semantic-ui-react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import './DateTime.scss';

export const selectFirstLocation = createSelector([(state) => state.locations], (locations) => locations?.list[0]);
const popupDateFormat = 'h:mm A MMM D, YYYY';

/**
 *
 * @param {epoch: number, format: string} param0
 * @returns html component
 */
const DateTime = ({ epoch, format }) => {
  const { t } = useTranslation();
  const dateTime = moment(epoch);
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  dateTime.tz(timezone);

  return (
    <Popup
      header={t('datetime.popup.header')}
      content={<PopupContent organizationTime={dateTime} />}
      trigger={renderDateTime(dateTime.format(format), dateTime.format('z'))}
    />
  );
};

const renderDateTime = (dateTimeString, timezoneString) => {
  return (
    <span className="ui-datetime-display">
      <span>{dateTimeString}</span>
      <Label size="mini" className="datetime-label">
        {timezoneString}
      </Label>
    </span>
  );
};

const PopupContent = ({ organizationTime }) => {
  const { t } = useTranslation();
  const yourTime = organizationTime.clone().tz(moment.tz.guess());
  const utcTime = organizationTime.clone().utc();

  return (
    <div className={'grid grid-cols-2 gap-1 text-xs'}>
      <div className={'mt-2 font-bold'}>
        {organizationTime.format('z')} &bull; {t('datetime.popup.time.label1')}
      </div>
      <div className={'mt-2 flex justify-end'}>{organizationTime.format(popupDateFormat)}</div>
      <div className={'font-bold'}>
        {yourTime.format('z')} &bull; {t('datetime.popup.time.label2')}
      </div>
      <div className={'flex justify-end'}>{yourTime.format(popupDateFormat)}</div>
      <div className={'font-bold'}>{utcTime.format('z')}</div>
      <div className={'flex justify-end'}>{utcTime.format(popupDateFormat)}</div>
    </div>
  );
};

export default DateTime;
