import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps, Form } from 'semantic-ui-react';
import { omit } from 'lodash';
import { DatePicker } from '../../../Components/Shared/DatePicker';
import { InlineError } from '../../../Components/Messages';
import { EnrollmentFormDataType } from '../../../enrollments';
import { TFunction } from 'i18next';

export const unenrollmentInitiatedByOptions = [
  'students.enrollment.unenrollmentInitiatedBy.parent',
  'students.enrollment.unenrollmentInitiatedBy.provider',
];

export const unenrollmentReasonOptions = [
  'students.enrollment.unenrollmentReason.familyMoves',
  'students.enrollment.unenrollmentReason.childAgedOutOfProgram',
  'students.enrollment.unenrollmentReason.childTransferredToOtherProgram',
  'students.enrollment.unenrollmentReason.cost',
  'students.enrollment.unenrollmentReason.schedule',
  'students.enrollment.unenrollmentReason.unmetNeeds',
  'students.enrollment.unenrollmentReason.dissatisfaction',
  'students.enrollment.unenrollmentReason.ownProgramCloses',
  'students.enrollment.unenrollmentReason.shortTermEnrollment',
  'students.enrollment.unenrollmentReason.other',
];

interface UnenrollmentReasonsProps {
  setFormData: Dispatch<SetStateAction<EnrollmentFormDataType>>;
  setErrors: Dispatch<SetStateAction<Record<string, string>>>;
  unenrollmentDate?: string;
  unenrollmentInitiatedBy?: string;
  unenrollmentReason?: string;
  isEnrolled?: boolean;
  onChangeDate: (_: any, { name, value, valid }: any) => void;
  errors: {
    unenrollmentDate?: string;
    unenrollmentInitiatedBy?: string;
    unenrollmentReason?: string;
  };
}

function createTextValueTranslator(t: TFunction) {
  return function translateStringToMatchingTextValueProps(text: string) {
    const translation = t(text);

    return {
      text: translation,
      value: translation,
    };
  };
}

export function StudentFormUnenrollmentReasons({
  setFormData,
  setErrors,
  onChangeDate,
  errors,
  unenrollmentDate,
  unenrollmentInitiatedBy,
  unenrollmentReason,
  isEnrolled,
}: UnenrollmentReasonsProps) {
  const { t } = useTranslation();
  const textToDropDownTranslator = createTextValueTranslator(t);

  const translatedUnenrollmentReasonOptions = unenrollmentReasonOptions.map(textToDropDownTranslator);

  const translatedUnenrollmentInitiatedByOptions = unenrollmentInitiatedByOptions.map(textToDropDownTranslator);

  function onDropDownChange(_: SyntheticEvent<HTMLElement>, { name, value }: DropdownProps) {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => omit(prev, name));
  }

  return (
    <>
      <h3 data-testid="unenrollmentSectionLabel">{t('enrollments.unenrollmentSectionLabel')}</h3>
      <Form.Field data-testid="unenrollmentDate-field">
        <DatePicker
          id="unenrollmentDate"
          name="unenrollmentDate"
          value={unenrollmentDate ?? ''}
          onChange={onChangeDate}
          required={!isEnrolled}
          label={t('enrollments.unenrollmentDateLabel')}
          placeholder={t('enrollments.unenrollmentDatePlaceholder')}
          error={!!errors.unenrollmentDate}
          closable
          data-testid="unenrollmentDate"
        >
          {errors.unenrollmentDate && (
            <InlineError
              text={errors.unenrollmentDate && t(errors.unenrollmentDate)}
              data-testid="unenrollmentDate-error-msg"
            />
          )}
        </DatePicker>
      </Form.Field>

      <Form.Field error={!!errors.unenrollmentInitiatedBy} data-testid="unenrollmentInitatedBy-field-error">
        <Form.Dropdown
          clearable
          id="unenrollmentInitiatedBy"
          name="unenrollmentInitiatedBy"
          label={t('enrollments.unenrollmentInitiatedByLabel')}
          placeholder={t('enrollments.unenrollmentInitiatedByPlaceholder')}
          selection
          selectOnBlur={false}
          value={unenrollmentInitiatedBy ?? ''}
          onChange={onDropDownChange}
          options={translatedUnenrollmentInitiatedByOptions}
          data-testid="unenrollmentInitiatedBy"
          error={errors.unenrollmentInitiatedBy && t(errors.unenrollmentInitiatedBy)}
        />
      </Form.Field>

      <Form.Field error={!!errors.unenrollmentReason} data-testid="unenrollmentReason-field-error">
        <Form.Dropdown
          clearable
          id="unenrollmentReason"
          name="unenrollmentReason"
          label={t('enrollments.unenrollmentReasonLabel')}
          placeholder={t('enrollments.unenrollmentReasonPlaceholder')}
          selection
          selectOnBlur={false}
          value={unenrollmentReason ?? ''}
          onChange={onDropDownChange}
          options={translatedUnenrollmentReasonOptions}
          data-testid="unenrollmentReason"
          error={errors.unenrollmentReason && t(errors.unenrollmentReason)}
        />
      </Form.Field>
    </>
  );
}
