// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import { getkinderSystemsReports, submitKSReport } from '../../../api/firebase/kinderSystems';
import { logError } from '../../../rollbar';
import { LoadingIndicator } from '../../Shared/BusyIndicator';
import { showErrorToast } from '../../Shared/showToast';

const AttendanceLogTable = ({ attendanceReports, setAttendanceReports }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const findSuccessReport = (failedReport) => {
    const found = attendanceReports?.list.some(
      (report) =>
        report.weekStart === failedReport.weekStart &&
        report.weekEnd === failedReport.weekEnd &&
        report.status === 'success'
    );
    return found;
  };

  const handleResubmit = async (data) => {
    try {
      setLoading(true);
      const { locationId, kinderSystemReportId, organizationId } = data;
      const reportData = await submitKSReport({
        locationId,
        kinderSystemReportId,
      });
      if (reportData) {
        const reports = await getkinderSystemsReports({
          organizationID: organizationId,
          locationID: locationId,
        });
        reports && setAttendanceReports(reports);
      }
    } catch (err) {
      logError('Error while resubmitting report: ', err);
      showErrorToast(t('Error while resubmitting report'), t('Oops, something went wrong!'), err);
    } finally {
      setLoading(false);
    }
  };

  if (!attendanceReports?.count) {
    return (
      <>
        <p>{t('No Reports Submitted')}</p>
      </>
    );
  }

  return (
    <Table stackable className="attendance-log-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('Sub. Period')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Sub. Date')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Provider ID')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Total Hours')}</Table.HeaderCell>
          <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingIndicator />}
        {attendanceReports?.list.map((report) => {
          return (
            <Table.Row key={report.id}>
              <Table.Cell>
                {`${moment.tz(report.weekStart, report.timezone).format('MMM DD')} - ${moment
                  .tz(report.weekEnd, report.timezone)
                  .format('MMM DD')}`}
              </Table.Cell>
              <Table.Cell>{moment.tz(report.dateOfSubmission, report.timezone).format('MM/DD/YYYY')}</Table.Cell>
              <Table.Cell>{report.providerID}</Table.Cell>
              <Table.Cell>
                {!isNaN(report.totalHours) && Math.floor(report.totalHours / 60) && (
                  <>
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <span className="bold">{Math.floor(report.totalHours / 60)}</span>h{' '}
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <span className="bold">{report.totalHours % 60}</span>m
                  </>
                )}
              </Table.Cell>
              {report.status === 'success' ? (
                <Table.Cell>
                  <span className="status success">
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <Icon color="green" name="check circle outline" />
                    Submitted
                  </span>
                </Table.Cell>
              ) : (
                <Table.Cell>
                  <span className="status failed">
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    <Icon color="red" name="times circle outline" />
                    Failed
                  </span>
                </Table.Cell>
              )}
              {report.status === 'success' ? (
                <Table.Cell />
              ) : (
                <Table.Cell>
                  <Icon
                    name="redo"
                    size="small"
                    className="pointer"
                    disabled={findSuccessReport(report)}
                    onClick={() =>
                      handleResubmit({
                        locationId: report.locationID,
                        kinderSystemReportId: report.id,
                        organizationId: report.organizationID,
                      })
                    }
                  />
                </Table.Cell>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default AttendanceLogTable;
