import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DateInput } from 'semantic-ui-calendar-react-yz';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';
import MomentPropTypes from 'react-moment-proptypes';
import { DefaultDateFormat } from '../../../helpers/dates';
import { deriveLanguage } from '../../../helpers/utils';

// i18n
// import 'moment/locale/es'; - TODO: CCMS-1047 - figure out i18n + moment

class DatePicker extends React.Component {
  static M_NOW = moment();

  static getInternalDateState(dateString, dateFormat) {
    return {
      initialDate: dateString,
      // always include now as a marked date
      markedDates: [DatePicker.M_NOW, moment(dateString, dateFormat)],
    };
  }

  constructor(props) {
    super(props);
    const dateFormat = this.deriveDateFormat();
    const initDateString = _.isEmpty(props.value)
      ? DatePicker.M_NOW.format(dateFormat)
      : moment(props.value, dateFormat).format(dateFormat);
    this.state = {
      ...DatePicker.getInternalDateState(initDateString, dateFormat),
      lastChangeWasDelete: false,
    };
    this.dateInputRef = React.createRef();
  }

  /* onChange(event, data{
    name, value, ...
    valid - if this is true, the date string is valid
  })*/
  // args isn't expected to be used as of now, but is here for future proofing
  onChangeInternal = (event, data, ...args) => {
    const { value } = data;
    const dateFormat = this.deriveDateFormat();

    // reset to current day if field is empty
    if (!value?.length) {
      this.hackyForceUpdate(DatePicker.M_NOW.format(dateFormat));
    }

    // first compare string lengths to short circuit unneeded date checking
    if (value.length === dateFormat.length) {
      // then if the date string is valid
      if (value === moment(value, dateFormat).format(dateFormat)) {
        // valid date found:
        data.valid = true;
        // make sure it's within bounds and update the original value
        data.value = this.sanitizeWithinDateRange(value);
        this.hackyForceUpdate(data.value);
      }
    }

    return this.props.onChange(event, data, ...args);
  };

  // force update on the calendar display by setting a new default date
  // which will also be used as a key, forcing React to aggressively re-render and close the popup
  // TODO: this is so hacky but this library doesn't support programmatic access.
  // we'll want to replace this one day when operations like this become more complex
  hackyForceUpdate = (newValue) => {
    this.setState(
      {
        // these values are necessary for date highlighting and isn't as hacky as the rest
        initialDate: newValue,
        markedDates: [DatePicker.M_NOW.now, moment(newValue, this.deriveDateFormat())],
      },
      () => {
        // if closable, this hacky rerender is unnecessary
        if (!this.props.closable) {
          const ref = this.dateInputRef.current; // DateInput component
          // trigger is the input element, picker is the calendar
          if (!ref.isTriggerInFocus()) {
            // without this if check, this will infinite loop
            ref.inputNode.focus(); // actual DOM input ref
          }
        }
      }
    );
  };

  sanitizeWithinDateRange = (dateVal) => {
    const dateFormat = this.deriveDateFormat();
    const thisDate = moment(dateVal, dateFormat);

    const mMinDate = moment(this.props.minDate, dateFormat);
    // either minDate is empty or dateVal should be after of it
    if (this.props.minDate && !thisDate.isAfter(mMinDate)) {
      return mMinDate.format(dateFormat);
    }

    const mMaxDate = moment(this.props.maxDate, dateFormat);
    // either maxDate is empty or dateVal should be before it
    if (this.props.maxDate && !thisDate.isBefore(mMaxDate)) {
      return mMaxDate.format(dateFormat);
    }

    return dateVal;
  };

  // if dateFormat is not provided, use default
  deriveDateFormat = () => {
    return _.isEmpty(this.props.dateFormat) ? DefaultDateFormat : this.props.dateFormat;
  };
  // if placeholder is not provided, use dateFormat as default
  derivePlaceholder = () => {
    return _.isEmpty(this.props.placeholder) ? this.deriveDateFormat() : this.props.placeholder;
  };

  deriveValue = () => {
    if (_.isEmpty(this.props.value)) {
      return '';
    }
    return this.props.value;
  };

  render() {
    const { onChange, value, t, i18n, tReady, ...otherProps } = this.props;
    return (
      <DateInput
        ref={this.dateInputRef}
        key={this.state.initialDate} /* hack to force close on matching dates */
        initialDate={this.props.maxDate ? moment(this.props.maxDate).startOf('month') : this.state.initialDate}
        marked={this.state.markedDates}
        onChange={this.onChangeInternal}
        value={this.deriveValue()}
        placeholder={this.derivePlaceholder()}
        dateFormat={this.deriveDateFormat()}
        popupPosition="bottom right"
        duration={0}
        localization={deriveLanguage()}
        {...otherProps}
        data-testid="date-input"
      />
    );
  }
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, MomentPropTypes.momentObj]),
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.string, MomentPropTypes.momentObj]),
  maxDate: PropTypes.oneOfType([PropTypes.string, MomentPropTypes.momentObj]),
  markColor: PropTypes.string, // semantic UI colors
};

// other defaults are set with some complicated logic
// see getDerivedStateFromProps
DatePicker.defaultProps = {
  dateFormat: DefaultDateFormat,
  markColor: 'blue',
};

export default withTranslation()(DatePicker);
