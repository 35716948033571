import dayjs from 'dayjs';
import { TimecardFormDataType } from '../timecardTypes';
import { FormErrorType } from '../../types';
import { checkIfTimeIsAfterClockIn } from './calculateClockInOutTimeDifference';
import { Timestamp } from 'firebase/firestore';

type ValidationData = {
  formData: TimecardFormDataType;
  timeDifferenceInMinutes: number;
  notClockedOut?: { clockedIn: Timestamp } | null;
};

// I suggest using a validation library for this kind of validation
export function validateTimeCards({
  formData,
  timeDifferenceInMinutes,
  notClockedOut = null,
}: ValidationData): FormErrorType {
  const errors: FormErrorType = {};
  if (!formData.clockedInDate) {
    errors.clockedInDate = 'errors.validation.startDateRequired';
  }

  if (formData?.clockedOutDate && dayjs(formData?.clockedOutDate).isBefore(formData?.clockedInDate, 'day')) {
    errors.clockedOutDate = 'errors.validation.outDateGreater';
  }

  const isClockOutEarlierThanClockIn = Boolean(timeDifferenceInMinutes) && timeDifferenceInMinutes < 0;

  if (isClockOutEarlierThanClockIn) {
    errors.clockedOutTime = 'errors.validation.clockOutBeforeClockIn';
  }

  if (!formData.clockedInTime) {
    errors.clockedInTime = 'errors.validation.startTimeRequired';
  }
  if (!formData.roomId) {
    errors.roomId = 'errors.validation.roomRequired';
  }
  if (!formData.staffId) {
    errors.staffName = 'errors.validation.staffNameRequired';
  }

  if (notClockedOut) {
    if (
      checkIfTimeIsAfterClockIn(notClockedOut.clockedIn, formData.clockedInDate, formData.clockedInTime) ||
      checkIfTimeIsAfterClockIn(notClockedOut.clockedIn, formData.clockedOutDate, formData.clockedOutTime)
    ) {
      errors.clockedOutRestriction = true;
    } else {
      if (!formData.clockedOutDate) {
        errors.clockedOutDate = 'errors.validation.endDateRequired';
      }
      if (!formData.clockedOutTime) {
        errors.clockedOutTime = 'errors.validation.endTimeRequired';
      }
    }
  }

  return errors;
}
