import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon, Image, Modal } from 'semantic-ui-react';

import { Dashboard } from '@uppy/react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import InlineError from '../../Components/Messages/InlineError';
import ShowErrors from '../../Components/Messages/ShowError';
import ShowSuccess from '../../Components/Messages/ShowSuccess';
import { DatePicker } from '../../Components/Shared/DatePicker';
import { Dropdown } from '../../Components/Shared/Dropdown';
import { CurrencyInput } from '../../Components/Shared/NumericInput';

import { addError } from '../../helpers/errors';
import { createUploadPath, resetUppy } from '../../helpers/uppy';
import { useUppy } from '../../hooks/useUppy';

import { selectFirstLocation } from '../../Components/DateTime/DateTime';
import { DefaultDateFormat, dateFormatter, toDateObject } from '../../helpers/dates';
import { parseCurrency } from '../../helpers/utils';
import { SEGMENT_EVENTS, useSegmentTrack } from '../../segment';
import { saveTransaction } from '../transactionsAPI';
import { useCategories } from '../transactionsHooks';
import { isExpenseTransactionType, isRevenueTransactionType } from '../types';

const initialFormState = {
  date: null,
  amount: '',
  category: '',
  target: '',
  notes: '',
};
const uppyOptions = {
  autoProceed: true,
};
export function TransactionModal({ organization, transaction, transactionType, onClose, onSave, open }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [formState, setFormState] = useState(initialFormState);
  const [receiptURL, setReceiptURL] = useState(transaction?.receiptURL);
  const [receiptType, setReceiptType] = useState(transaction?.receiptType);

  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  const formLabels = useMemo(
    () => createFormLabels(transaction, transactionType, t),
    [transaction, transactionType, t]
  );
  const uploadPath = useMemo(() => createUploadPath('transactions', transaction), [transaction]);
  const onUploadedLocal = useCallback(({ downloadUrl, extension }) => {
    setReceiptURL(downloadUrl);
    setReceiptType(extension);
  }, []);

  const uppy = useUppy({
    uploadPath,
    onUploaded: onUploadedLocal,
    options: uppyOptions,
    unique: true,
  });

  const transactionRef = useRef(transaction);

  const categories = useCategories(organization.id, transactionType, t);
  const categoryOptions = createCategoryOptions(categories, getTransactionType(), t);

  const segmentTrack = useSegmentTrack();

  initializeFormStateFromTransaction();
  const isTransactionBeingAdded = !transaction?.id;

  return (
    <Modal onClose={onCloseLocal} open={open} closeIcon size="small" closeOnDimmerClick={false}>
      <Modal.Header data-testid="transaction-modal-header">{formLabels.header}</Modal.Header>
      <Modal.Content>
        {renderMessages()}
        {formState && renderForm()}
      </Modal.Content>
    </Modal>
  );

  function renderForm() {
    return (
      <Form noValidate loading={loading}>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>{!successMessage && renderReceipt()}</Grid.Column>
            <Grid.Column>{!successMessage && renderInputs()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{renderButtons()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  function renderReceipt() {
    if (receiptURL) {
      return (
        <>
          <Button
            data-testid="remove-receipt-button"
            basic
            color="blue"
            size="mini"
            compact
            onClick={() => {
              resetUppy(uppy);
              setReceiptURL(null);
              setReceiptType(null);
            }}
          >
            <Icon name="delete" /> {t('transaction.removeReceipt')}
          </Button>
          {receiptType === 'pdf' ? (
            <object data={receiptURL} type="application/pdf" width="100%" height="100%" style={{ padding: '5px 0' }} />
          ) : (
            <Image data-testid="receipt-image" src={receiptURL} size="medium" rounded />
          )}
        </>
      );
    }

    if (uppy) {
      // Otherwise, show the Uppy uploader
      return (
        <Dashboard
          data-testid="uppy-dashboard"
          uppy={uppy}
          hideProgressAfterFinish
          inline
          proudlyDisplayPoweredByUppy={false}
          onRequestClose={onClose}
          height={350}
        />
      );
    }

    return null;
  }
  function renderInputs() {
    return (
      <>
        <Form.Group widths={2}>
          <Form.Field error={!!errors?.date}>
            <DatePicker
              data-testid="add-transaction-date-picker"
              required
              name={'date'}
              value={formState.date}
              placeholder={t('MM/DD/YYYY')}
              label={formLabels.date}
              onChange={onChange}
            />
            {errors?.date && <InlineError text={errors.date} />}
          </Form.Field>
          <Form.Field error={!!errors?.amount}>
            <Form.Input
              data-testid="add-transaction-amount-input"
              required
              type="text"
              id="amount"
              name={'amount'}
              value={formState.amount}
              placeholder="$0.00"
              label={t('Amount')}
              onChange={onChange}
              control={CurrencyInput}
            />
            {errors?.amount && <InlineError text={errors.amount} />}
          </Form.Field>
        </Form.Group>
        <Form.Field width={16} error={!!errors?.category}>
          {categoryOptions && (
            <Dropdown
              data-testid="add-transaction-category-dropdown"
              required
              clearable
              name={'category'}
              value={formState.category}
              placeholder={formLabels.category}
              label={formLabels.category}
              onChange={onChange}
              options={categoryOptions}
            />
          )}
          {errors?.category && <InlineError text={errors.category} />}
        </Form.Field>
        <Form.Field error={!!errors?.target}>
          <Form.Input
            data-testid="add-transaction-target-input"
            required
            name={'target'}
            value={formState.target}
            placeholder={formLabels.targetPlaceholder}
            label={formLabels.target}
            onChange={onChange}
          />
          {errors?.target && <InlineError text={errors.target} />}
        </Form.Field>
        <Form.Field>
          <Form.TextArea
            data-testid="add-transaction-notes-input"
            name={'notes'}
            value={formState.notes}
            placeholder={formLabels.notesPlaceholder}
            label={formLabels.notes}
            onChange={onChange}
            rows={3}
          />
        </Form.Field>
      </>
    );
  }
  function renderButtons() {
    const addAnotherText = t('Add Another ' + getTransactionType());
    return (
      <Form.Field>
        {!successMessage && (
          <>
            <Button
              data-testid="submit-transaction-button"
              primary
              disabled={loading}
              loading={loading}
              content={t('transaction.submit')}
              onClick={onSubmit}
            />
            <Button
              data-testid="cancel-transaction-button"
              basic
              loading={loading}
              content={t('Cancel')}
              onClick={onCloseLocal}
            />
          </>
        )}
        {successMessage && (
          <>
            <Button
              data-testid="done-transaction-button"
              primary
              loading={loading}
              content={t('Done')}
              onClick={onCloseLocal}
            />
            {isTransactionBeingAdded && (
              <Button
                data-testid="add-another-transaction-button"
                primary
                loading={loading}
                content={addAnotherText}
                onClick={clearFormState}
              />
            )}
          </>
        )}
      </Form.Field>
    );
  }
  function renderMessages() {
    return (
      <>
        {errors?.server && <ShowErrors content={errors.server} />}
        {successMessage && <ShowSuccess content={successMessage} />}
      </>
    );
  }
  async function onSubmit(e) {
    e.preventDefault();
    if (loading) return;

    let errors = null;

    try {
      setLoading(true);
      errors = validate(formState, formLabels, t);

      if (!errors) {
        let _transaction = createTransactionFromFormState();
        _transaction = await saveTransaction(_transaction);
        setSuccessMessage(t('Transaction Saved'));
        transactionRef.current = _transaction;

        if (isTransactionBeingAdded) segmentTrack(SEGMENT_EVENTS.transactionCreated, _transaction);

        if (onSave) onSave(_transaction, isTransactionBeingAdded);
      } else {
        setErrors(errors);
      }
    } catch (error) {
      errors = addError(errors, 'server', t('transaction.error.savingTransaction') + ': ' + error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onCloseLocal() {
    clearFormState();
    onClose();
  }

  function onChange(e, { name, value }) {
    setFormState({ ...formState, [name]: value });
    setErrors(null);
  }

  function getTransactionType() {
    return transaction?.transactionType ?? transactionType;
  }
  function clearFormState() {
    resetUppy(uppy);
    setFormState(initialFormState);
    transactionRef.current = null;
    setSuccessMessage(null);
    setReceiptURL(null);
    setReceiptType(null);
    setErrors(null);
  }
  function initializeFormStateFromTransaction() {
    if (transaction && transactionRef.current !== transaction) {
      transactionRef.current = transaction;
      const date = dateFormatter(transaction.date ?? new Date());

      setFormState({
        date: date ?? new Date(),
        amount: transaction.amount ?? '',
        category: transaction.category ?? null,
        target: isRevenueTransactionType(transaction.transactionType ?? transactionType)
          ? transaction.payer ?? ''
          : transaction.payee ?? '',
        notes: transaction.notes ?? '',
      });
      setReceiptURL(transaction?.receiptURL);
      setReceiptType(transaction?.receiptType);
    }
  }
  function createTransactionFromFormState() {
    const payer = isRevenueTransactionType(transactionType) ? formState.target : organization.name;
    const payee = isExpenseTransactionType(transactionType) ? formState.target : organization.name;

    const amount = formState.amount ? parseCurrency(formState.amount) : 0;

    const _transaction = {
      date: formState.date ? toDateObject(moment(formState.date, DefaultDateFormat).tz(timezone, true)) : new Date(),
      amount: amount ?? 0,
      transactionType: transaction?.transactionType ?? transactionType,
      category: formState.category ?? '',
      notes: formState.notes ?? '',
      receiptURL: receiptURL ?? '',
      receiptType: receiptType ?? '',
      organizationId: organization.id,
      organizationName: organization.name,
      payer,
      payee,
    };
    if (transaction?.id) {
      _transaction.id = transaction.id;
    }
    return _transaction;
  }
}

function createCategoryOptions(categories, transactionType, t) {
  if (!categories || !transactionType) return null;

  const categoriesOfType = categories[transactionType];

  return categoriesOfType?.map((category) => {
    return {
      key: category,
      value: category,
      text: t(category),
    };
  });
}
function createFormLabels(transaction, _transactionType, t) {
  const headerLabel = transaction ? 'Edit' : 'Add New';
  const transactionType = transaction?.transactionType ?? _transactionType;
  const header = t(headerLabel + ' ' + transactionType);

  return {
    header: header,
    date: t('Date'),
    amount: t('Amount'),
    category: isExpenseTransactionType(transactionType) ? t('Expense Category') : t('Revenue Category'),
    target: isExpenseTransactionType(transactionType) ? t('Merchant') : t('Payer'),
    targetPlaceholder: isExpenseTransactionType(transactionType) ? t('Who did we pay?') : t('Who paid us?'),
    notes: t('Notes'),
    notesPlaceholder: t('Anything else?'),
  };
}

function validate(formState, formLabels, t) {
  let errors = null;

  if (!formState.date) errors = addError(errors, 'date', t('Date is required'));
  if (!formState.amount) errors = addError(errors, 'amount', t('Amount is required'));
  if (!formState.category) errors = addError(errors, 'category', t('Category is required'));
  if (!formState.target) errors = addError(errors, 'target', t(`${formLabels.target} is required`));

  return errors;
}

export default TransactionModal;
