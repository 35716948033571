export const routes = {
  home: '/',
  login: '/login',
  billing: {
    hashes: {
      details: '#details',
      items: '#items',
      students: '#students',
      confirm: '#confirm',
    },
    plans: {
      list: '/billing/plans',
      edit: '/billing/plans/edit',
    },
    invoices: {
      list: '/billing/invoices',
      edit: '/billing/invoices/edit',
      reports: '/billing/invoices/reports',
    },
    reports: '/billing/reports',
    details: '/billing/invoice/details',
    items: '/billing/invoice/items',
    detailsRecurringHash: '#recurring-plans',
    select: '/billing/invoice/select',
    confirm: '/billing/invoice/confirm',
  },
  authError: '/auth-error',
  authReturn: '/auth-return',
  signout: '/signout',
  signoutFull: '/signout-full',
  completeSignup: '/complete-signup',
  signin: '/signin',
  signup: '/signup',
  profile: '/profile',
  setup: '/setup',
  verify: '/verify',
  r404: '/404',
  r401: '/401',
  settings: '/settings',
  invoice: '/invoice',
  staff: '/staff',
  students: '/students',
  studentsImport: '/import/students',
  importStudents: '/import/students',
  featureFlags: '/feature-flags',
  error: '/error',
  formsAndDocuments: '/documents',
  appstore: '/appstore',
  learningBeyondPaper: '/appstore/learning-beyond-paper',
  debug: '/debug',
};
