import { FoodProgramQueryProvider, NewMealRecordForm } from '@wonderschool/common-food-program-ui';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getCurrentEnvironment } from '../../../common/utils/environmentUtils';
import { getTitlePrefix } from '../../../config/env';
import { useAuthUser } from '../../../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MealRecordCreate = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.meals.record.create');
  const { gotoRouteByName, goBack } = useRoutes();
  const { token } = useAuthUser();

  useSegmentPage(PAGE_NAMES.foodProgramMealRecordCreate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <div className="h-[calc(100vh-173px)] max-w-7xl overflow-hidden">
        <div className={twMerge('flex size-full flex-col overflow-y-auto')}>
          <NewMealRecordForm
            initialStep={0}
            onCancel={goBack}
            onCompletedSaving={(event) => {
              gotoRouteByName(RouteNameEnum.CALENDAR, [
                {
                  name: 'servedDate',
                  value: event.servedAt,
                },
              ]);
            }}
          />
        </div>
      </div>
    </FoodProgramQueryProvider>
  );
};
export default MealRecordCreate;
