import { useTranslation } from 'react-i18next';
import { Icon, Message } from 'semantic-ui-react';

export type Alert = {
  type: 'error' | 'success';
  message: string;
};
export type AlertMessageProps = {
  alert?: Alert | null;
  setAlert: (alert: Alert | null) => void;
};
export default function AlertMessage(props) {
  const { alert, setAlert } = props;

  const { t } = useTranslation();

  if (!alert || !alert.type || !alert.message) return null;

  const isError = alert.type === 'error';
  return (
    <Message icon onDismiss={() => setAlert(null)}>
      <Icon name={isError ? 'exclamation circle' : 'check circle outline'} color={isError ? 'red' : 'green'} />
      <Message.Content>
        <Message.Header>{isError ? t('Ups!') : t('Success!')}</Message.Header>
        <span>{t(alert.message)}</span>
      </Message.Content>
    </Message>
  );
}
