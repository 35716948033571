import { FoodProgramQueryProvider, MealPlanningPage } from '@wonderschool/common-food-program-ui';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getCurrentEnvironment } from '../../../common/utils/environmentUtils';
import { getTitlePrefix } from '../../../config/env';
import { useAuthUser } from '../../../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MealPlanning = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.meals.list');
  const { gotoRouteByName } = useRoutes();
  const { token } = useAuthUser();
  useSegmentPage(PAGE_NAMES.foodProgramMeals);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <MealPlanningPage
        onCreateMeal={() => {
          gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_CREATE);
        }}
        onEditMeal={(mealId: string) => {
          gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_UPDATE, [{ name: 'mealId', value: mealId }]);
        }}
        onRecordMeal={() => gotoRouteByName(RouteNameEnum.CALENDAR)}
        onNavigateToCreateMeal={() => gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_CREATE)}
      />
    </FoodProgramQueryProvider>
  );
};
export default MealPlanning;
