import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Checkbox, Dropdown, DropdownProps, Icon, Input, Label, Popup } from 'semantic-ui-react';
import { didStripeOnboardingSucceed } from '../../../helpers/stripe';
import DateTime from '../../DateTime/DateTime';

// Import actions
import { invoiceSelected } from '../../../redux/actions/invoiceActions';

// Import components
import { LoadingIndicator } from '../../Shared/BusyIndicator';
import NoDataMessageBilling from '../../Shared/NoDataMessageBilling';
import SectionCard from '../../Shared/SectionCard';

import { markInvoicesAsArchive, resendInvoiceToStripe } from '../../../api/firebase/invoices';
import { DefaultDateFormat } from '../../../helpers/dates';
import { convertListToCSV, currencyFormatter } from '../../../helpers/utils';
import { InvoiceStatus as InvoiceStatusComponent } from './index';

// Import styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { isLocal } from '../../../config/env';
import {
  InvoiceStatus,
  invoiceStatusFormatter,
  isInvoiceElegibleForArchive,
  isV2Invoice,
} from '../../../helpers/invoices';
import { showSetupPayoutRibbon } from '../../../helpers/stripe';
import { USER_STATUS } from '../../../helpers/userStatus';
import { useInvoices } from '../../../hooks/useInvoices';
import { useOrganization } from '../../../hooks/useOrganizations';
import { ShowErrors } from '../../Messages';
import SetupPayoutBanner from '../../SetupPayoutBanner';
import AlertMessage from '../../Shared/AppAlert';
import { DateRangePicker } from '../../Shared/DatePicker';
import './InvoiceList.scss';

import {
  Button,
  DataTable,
  MainContentLayout,
  SlideOver,
  SortByEnum,
  SortDirectionEnum,
  Tooltip,
  WidgetSizeEnum,
} from '@wonderschool/common-base-ui';
import { Invoice } from '@wonderschool/ws-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import compact from 'lodash/compact';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { InvoiceDetailContainer } from '../../Forms/Billing/InvoiceDetail/InvoiceDetailContainer';
import { InvoiceFilters } from '../../Forms/Billing/InvoicePlans/InvoicePlansList';
import InvoicesAtGlance from '../FinanceAtGlance/InvoicesAtGlance';
import SetupPayoutRequirements from '../SetupPayoutRequirements';
import EnablePaymentsModal from './EnablePaymentsModal';

export const INVOICE_TYPE = 'all';

const selectCurrentOrganization = createSelector(
  [(state) => state.organizations],
  (organizations) => organizations?.currentOrganization
);

const PageFilters = ({
  filters,
  setFilters,
}: {
  filters: InvoiceFilters;
  setFilters: (filters: InvoiceFilters) => void;
}) => {
  const { t } = useTranslation();
  const { invoiceStatus, dueDateRange, hideArchived } = filters;

  const statusOptions = useMemo(
    () => [
      { key: InvoiceStatus.DRAFT, value: InvoiceStatus.DRAFT, text: t('New') },
      {
        key: USER_STATUS.SCHEDULED,
        value: USER_STATUS.SCHEDULED,
        text: t('status.scheduled'),
      },
      {
        key: InvoiceStatus.OPEN,
        value: InvoiceStatus.OPEN,
        text: t('Invoice Sent'),
      },
      {
        key: USER_STATUS.NOTIFICATION_SENT,
        value: USER_STATUS.NOTIFICATION_SENT,
        text: t('status.notificationSent'),
      },
      {
        key: USER_STATUS.PAST_DUE,
        value: USER_STATUS.PAST_DUE,
        text: t('status.pastDue'),
      },
      {
        key: USER_STATUS.TRANSFERRING,
        value: USER_STATUS.TRANSFERRING,
        text: t('status.transferring'),
      },
      {
        key: InvoiceStatus.PAID,
        value: InvoiceStatus.PAID,
        text: t('status.deposited'),
      },
      {
        key: `${InvoiceStatus.PAID}(O)`,
        value: `${InvoiceStatus.PAID}(O)`,
        text: t('status.paidManually'),
      },
      {
        key: InvoiceStatus.PROCESSING,
        value: InvoiceStatus.PROCESSING,
        text: t('Processing'),
      },
      {
        key: InvoiceStatus.FAILED,
        value: InvoiceStatus.FAILED,
        text: t('Failed'),
      },
      {
        key: USER_STATUS.CHARGE_FAILED,
        value: USER_STATUS.CHARGE_FAILED,
        text: t('status.chargeFailed'),
      },
      {
        key: USER_STATUS.TRANSFER_FAILED,
        value: USER_STATUS.TRANSFER_FAILED,
        text: t('status.transferFailed'),
      },
      {
        key: InvoiceStatus.UNCOLLECTIBLE,
        value: InvoiceStatus.UNCOLLECTIBLE,
        text: t('Uncollectible'),
      },
      { key: InvoiceStatus.VOID, value: InvoiceStatus.VOID, text: t('Void') },
    ],
    [t]
  );

  const onChange =
    (updateField: string) =>
    (_, { value }) => {
      setFilters({ ...filters, [updateField]: value });
    };

  const onInputChange = (updateField) => (e) => {
    setFilters({ ...filters, [updateField]: e.currentTarget.value });
  };

  const handleChangeStatusFilter = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setFilters({ ...filters, invoiceStatus: data.value as string });
  };
  return (
    <>
      <div className="invoice-filter-row">
        <Dropdown
          data-testid="invoice-status-filter"
          placeholder={t('allStatusesLabel')}
          selection
          clearable
          value={invoiceStatus}
          onChange={handleChangeStatusFilter}
          options={statusOptions}
        />
        <Input
          data-testid="invoice-description-filter"
          placeholder={t('Search Description')}
          onChange={onInputChange('descriptionSearch')}
        />
        <DateRangePicker
          data-testid="invoice-due-date-filter"
          placeholder={t('All Due Dates')}
          value={dueDateRange}
          clearable
          allowSameEndDate
          onChange={onChange('dueDateRange')}
          className="date-range-picker"
          closeOnMouseLeave={false}
        />
        <Input
          data-testid="invoice-amount-filter"
          placeholder={t('Search Amount')}
          onChange={onInputChange('amountSearch')}
          className="filter-input"
        />
        <Input
          data-testid="invoice-student-filter"
          placeholder={t('Search Student/Parent')}
          icon="search"
          iconPosition="left"
          onChange={onInputChange('studentSearch')}
        />
      </div>
      <div className="invoice-archive-row">
        <Checkbox
          data-testid="invoice-hide-archived-filter"
          label={t('invoiceList.hideArchiveInvoices')}
          onChange={(e, data) => setFilters({ ...filters, hideArchived: !!data.checked })}
          checked={hideArchived}
        />
      </div>
    </>
  );
};

function InvoicesList({ onClickCreateNewInvoice }: { onClickCreateNewInvoice: () => void }) {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const csvDownloadColumns = [
    'Id',
    'Students',
    'Parent',
    'Description',
    'Due Date',
    'Invoice Date',
    'Amount',
    'Status',
  ];
  const [filters, setFilters] = React.useState<InvoiceFilters>({
    invoiceStatus: '',
    billingInterval: '',
    descriptionSearch: '',
    startDateRange: '',
    dueDateRange: '',
    amountSearch: '',
    studentSearch: '',
    nextInvoiceDateRange: '',
    hideArchived: true,
    balanceDueSearch: '',
  });
  const [selected, setSelected] = React.useState<any[]>([]);

  const invoices = useInvoices(filters);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const invoiceType = invoices.invoiceType || INVOICE_TYPE;
  const invoicesList = invoices[invoiceType];

  const isEmpty = !!invoices?.isLoaded && invoicesList && !invoicesList.count;

  const accountRequirements = currentOrganization?.stripe?.onboardingRequirements;
  const arePaymentsEnabled = currentOrganization?.stripe?.paymentsEnabled;

  const organization = useOrganization();
  const { isFinanceAtAGlanceEnabled } = useFlags();

  const tableColumns = useMemo(
    () =>
      compact([
        {
          fieldName: 'student.displayName',
          label: t('Students'),
          sortBy: SortByEnum.CLIENT,
          sortFunction: (a, b) => {
            if (!a.student?.displayName || !b.student?.displayName) return 0;

            return a.student.displayName.localeCompare(b.student.displayName);
          },
          renderCell: (row) => {
            return (
              <>
                {isV2Invoice(row) ? (
                  // eslint-disable-next-line i18next/no-literal-string
                  <Label data-testid={`v2-label-${row.id}`} className="v2-label" size="mini">
                    v2
                  </Label>
                ) : null}
                <div data-testid={`student-column-value-${row.id}`}>{row.student.displayName}</div>
                {row.isArchived ? (
                  <div data-testid={`invoice-archived-label-${row.id}`} className="invoice-archived-label">
                    {t('invoiceList.archived.text')}
                  </div>
                ) : (
                  ''
                )}
              </>
            );
          },
        },
        {
          fieldName: 'description',
          label: t('Description'),
          renderCell: (row) => {
            if (isV2Invoice(row)) {
              return <span data-testid={`description-column-value-${row.id}`}>{row.itemsDescription}</span>;
            }

            return (
              <span data-testid={`description-column-value-${row.id}`}>
                {row.invoiceItemList
                  .map((c) => {
                    return t(c.item);
                  })
                  .join(', ')}
              </span>
            );
          },
        },
        {
          fieldName: 'isInvoice',
          label: '',
          renderCell: (row) =>
            !row.isInvoice ? (
              <Popup
                trigger={<Icon data-testid={`recurring-plan-icon-${row.id}`} name="sync" />}
                content={<span data-testid={`recurring-plan-tooltip-${row.id}`}>{t('Recurring plan')}</span>}
                offset={[-15, 0]}
              />
            ) : (
              ''
            ),
        },
        {
          fieldName: 'dateDue',
          label: t('Due Date'),
          sortBy: SortByEnum.CLIENT,
          renderCell: (row) => (
            <span data-testid={`due-date-column-value-${row.id}`}>
              <DateTime epoch={row.dateDue} format={DefaultDateFormat} />
            </span>
          ),
        },
        {
          fieldName: 'total',
          label: t('Amount'),
          sortBy: SortByEnum.CLIENT,
          renderCell: (row) => (
            <span data-testid={`amount-column-value-${row.id}`}>{currencyFormatter(row.total, { precision: 2 })}</span>
          ),
        },
        {
          fieldName: 'status',
          label: t('Status'),
          sortBy: SortByEnum.CLIENT,
          renderCell: (row) => (
            <span data-testid={`status-column-value-${row.id}`}>
              <InvoiceStatusComponent invoice={row} withTooltip />
            </span>
          ),
        },
        isLocal() && {
          fieldName: '',
          label: '',
          renderCell: (row) => {
            return (
              <Button
                primary
                size={WidgetSizeEnum.X_SMALL}
                onClick={(event) => onResendClick(event, row, setAlertMessage)}
              >
                {t('Resend')}
              </Button>
            );
          },
        },
      ]),
    [t]
  );

  const canCreateInvoice = useMemo(() => {
    return arePaymentsEnabled && didStripeOnboardingSucceed(organization);
  }, [arePaymentsEnabled, organization]);

  return (
    <MainContentLayout
      title={t('All Invoices')}
      primaryAction={{
        label: (
          <Tooltip action={canCreateInvoice ? 'none' : 'hover'} content={t('invoiceList.connectPayoutAccount')}>
            {t('Create New Invoice')}
          </Tooltip>
        ),
        size: WidgetSizeEnum.MEDIUM,
        'data-testid': 'add-invoice-button',
        onClick: onClickCreateNewInvoice,
        disabled: !canCreateInvoice,
      }}
    >
      <div className="my-4">
        <div className="grid grid-cols-1">
          {isFinanceAtAGlanceEnabled && !isEmpty && !showSetupPayoutRibbon(organization) && <InvoicesAtGlance />}
          {arePaymentsEnabled ? <PageFilters filters={filters} setFilters={setFilters} /> : null}
        </div>
        <SectionCard header={renderInvoiceListHeader()} className={!arePaymentsEnabled ? 'no-shadow' : ''}>
          {arePaymentsEnabled ? null : <EnablePaymentsModal />}
          <SetupPayoutRequirements
            organizationId={currentOrganization?.id}
            accountRequirements={accountRequirements}
            onboardingStatus={currentOrganization?.stripe?.onboardingStatus}
            onError={(error) => setErrorMessage(error)}
          />
          <SetupPayoutBanner onError={(error) => setErrorMessage(error)} />
          {errorMessage && <ShowErrors content={errorMessage} />}
          {!invoices.isLoaded && <LoadingIndicator />}
          {isEmpty && renderNoDataMessage()}
          {!isEmpty && invoices.isLoaded && renderInvoicesList()}
        </SectionCard>
      </div>
    </MainContentLayout>
  );

  function renderInvoiceListHeader() {
    return (
      <div className="flex justify-end gap-x-4">
        <Button
          data-testid="csv-download-invoices-button"
          disabled={invoicesList.list.length === 0 || isExportLoading}
          size={WidgetSizeEnum.SMALL}
          onClick={onClick_CSVDownloadButton}
        >
          {isExportLoading && <LoadingIndicator />}
          <Icon name="download" data-testid="csv-download-invoices-icon" /> {t('Download')}
        </Button>
        <Button
          data-testid="archive-invoices-button"
          disabled={selected?.length === 0}
          primary
          size={WidgetSizeEnum.SMALL}
          onClick={onClick_ArchiveButton}
        >
          <Icon name="archive" data-testid="archive-invoices-icon" /> {t('invoiceList.archiveButton.text')}
        </Button>
      </div>
    );
  }

  function renderInvoicesList() {
    return (
      <>
        <AlertMessage alert={alertMessage} setAlert={setAlertMessage} />

        <DataTable
          key={invoicesList.list.length}
          canSelect
          canSelectAll
          hasPagination={true}
          numItemsPerPage={25}
          headerClasses="px-2"
          rowClasses="[&>td:first-child]:w-4 h-16"
          data={Object.values(invoicesList.list)}
          defaultSortConfig={{ name: 'dateDue', direction: SortDirectionEnum.DESC }}
          columns={tableColumns}
          onChangeSelectedRows={(selectedRows) => {
            const selectedCanBeArchived = selectedRows.filter((row) => isInvoiceElegibleForArchive(row));
            setSelected(selectedCanBeArchived);
          }}
          onClickRow={(row: Invoice) => {
            dispatch(invoiceSelected(row, invoiceType));
          }}
          isSelectionDisabled={(row) => !isInvoiceElegibleForArchive(row)}
        />

        {renderInvoiceDetail()}
      </>
    );
  }

  function renderInvoiceDetail() {
    const selectedInvoice = invoicesList.selected;
    const displayName = selectedInvoice?.student?.displayName || '';
    const invoiceId = selectedInvoice?.id || '';

    return (
      <SlideOver
        title={t('Invoice for {{displayName}}', { displayName })}
        subTitle={t('Invoice # {{invoiceId}}', { invoiceId })}
        isOpen={!!selectedInvoice}
        onClose={() => {
          if (invoiceId) refreshInvoice(currentOrganization, invoiceId);
          dispatch(invoiceSelected(null, invoiceType));
        }}
      >
        <InvoiceDetailContainer
          invoices={invoicesList}
          invoiceType={invoiceType}
          initallySelectedInvoice={selectedInvoice}
        />
      </SlideOver>
    );
  }
  function refreshInvoice(_currentOrganization, _invoiceId) {
    //    refreshOrganizationInvoice(currentOrganization.id, invoiceId, INVOICE_TYPE);
  }
  function renderNoDataMessage() {
    return <NoDataMessageBilling title={t('invoiceList.emptyTitle')} subTitle={t('invoiceList.emptySubtitle')} />;
  }

  async function onClick_ArchiveButton() {
    const selectedCanBeArchived = selected.filter((row) => isInvoiceElegibleForArchive(row));
    await markInvoicesAsArchive(currentOrganization.id, selectedCanBeArchived);
    setSelected([]);
  }

  function onClick_CSVDownloadButton() {
    setIsExportLoading(true);
    const formattedList = Object.values(invoicesList.list).map((invoice) => {
      const invoiceItemDescription = isV2Invoice(invoice)
        ? invoice.itemsDescription
        : invoice.invoiceItemList.map((c) => t(c.item)).join(', ');

      const { text: statusText } = invoiceStatusFormatter(invoice, t);

      return {
        invoiceId: invoice.id,
        student: invoice.student.displayName,
        parent: getParentName(invoice),
        description: t(invoiceItemDescription),
        dateDue: moment(invoice.dateDue).format(DefaultDateFormat),
        invoiceDate: moment(invoice.createdAt).format(DefaultDateFormat),
        total: currencyFormatter(invoice.total, { precision: 2 }),
        status: t(statusText),
      };
    });

    //sort formattedList by datedue
    formattedList.sort((x, y) => {
      return moment(x.dateDue).isBefore(y.dateDue) ? -1 : 1;
    });

    try {
      const csvData = convertListToCSV(formattedList, csvDownloadColumns, t);
      const url = window.URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${currentOrganization.name}-invoices.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExportLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsExportLoading(false);
    }
  }
}

export function getParentName(invoice) {
  if (isV2Invoice(invoice)) {
    return invoice.billedTo?.displayName || '';
  } else {
    return invoice.student?.responsibleForBilling
      ? invoice.student?.family[invoice.student?.responsibleForBilling.id]?.displayName || ''
      : '';
  }
}

async function onResendClick(event, invoice, setAlertMessage) {
  try {
    event.stopPropagation();
    await resendInvoiceToStripe(invoice);
    setAlertMessage({
      type: 'success',
      message: 'Invoice successfully resent',
    });
  } catch (error) {
    console.error(error);
    setAlertMessage({
      type: 'error',
      message: 'Something went wrong, please try again',
    });
  }
}

export default InvoicesList;
