import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import DevicesList from '../Components/Devices/DevicesList';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

const Devices = (_props) => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.devices);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Shared Devices') })}
      </title>
      <body className="devices" />
    </Helmet>
  );

  return (
    <MainContentLayout>
      {renderPageHead()}

      <DevicesList />
    </MainContentLayout>
  );
};

export default Devices;
