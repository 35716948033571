import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { logError } from '../../rollbar';

import { useContacts } from '../../contacts';

import { DailyHoursType, TimecardType } from '../types';

import { deleteTimecard } from '../timecardsAPI';
import { TimecardDeleteConfirmationModal } from './TimecardDeleteConfirmationModal';
import { TimecardDiscardConfirmationModal } from './TimecardDiscardConfirmationModal';
import TimecardModal from './TimecardModal';
import TimecardEditModalOld from './TimecardEditModalOld';
import { TimesheetDataTable } from './TimesheetDataTable';

export enum SortColumnEnum {
  NAME = 'displayName',
  ROOM = 'room',
  DATE = 'clockedInDateSort',
  IN_TIME = 'clockedInTimeSort',
  OUT_TIME = 'clockedOutTimeSort',
  TOTAL = 'totalHoursSort',
}

type DailyHoursTableProps = {
  dailyHours: DailyHoursType[];
  timecards: TimecardType[];
  onSave: (timecard: TimecardType) => void;
  onClear: () => void;
  setRefreshTimeCard: () => void;
};

const DailyHoursTable: React.FC<DailyHoursTableProps> = ({
  timecards,
  dailyHours,
  onSave,
  onClear,
  setRefreshTimeCard,
}) => {
  const { allStaffContacts } = useContacts();
  const { isTimecardEnabled } = useFlags();

  const [selectedTimecard, setSelectedTimecard] = useState<TimecardType | undefined>();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const selectedStaff = useMemo(() => {
    if (!selectedTimecard || !allStaffContacts) return undefined;
    return allStaffContacts.find((staffContact: any) => selectedTimecard.contactId === staffContact.id);
  }, [selectedTimecard, allStaffContacts]);

  const onTimecardSelected = useCallback(
    (timecardId: string) => {
      const timecard = timecards.find((timecard) => timecard.id === timecardId);
      setSelectedTimecard(timecard);
      setOpenEditModal(true);
    },
    [timecards]
  );

  const switchDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  function deleteTimecardClick(timecardData?: TimecardType) {
    if (!timecardData) {
      return;
    }

    try {
      return deleteTimecard(timecardData);
    } catch (err) {
      logError('Error deleting timecards', err);
    } finally {
      onClear();
      setRefreshTimeCard();
    }
  }

  return (
    <div className="mt-4 overflow-x-scroll sm:overflow-x-hidden">
      <TimesheetDataTable dailyHours={dailyHours} onTimecardSelected={onTimecardSelected} />

      {isTimecardEnabled ? (
        <TimecardModal
          key={selectedTimecard?.id}
          timecard={selectedTimecard}
          staff={selectedStaff}
          onClose={() => setSelectedTimecard(undefined)}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          switchDeleteModal={switchDeleteModal}
          setOpenCancelModal={setOpenCancelModal}
          onSave={onSave}
          openCancelModal
          openDeleteModal={openDeleteModal}
          isEdit
        />
      ) : (
        <TimecardEditModalOld
          timecard={selectedTimecard}
          staff={selectedStaff}
          onClose={() => setSelectedTimecard(undefined)}
          onSave={onSave}
        />
      )}

      <TimecardDeleteConfirmationModal
        openDeleteModal={openDeleteModal}
        onCancelDeletion={() => setOpenDeleteModal(false)}
        onConfirmDeletion={() => deleteTimecardClick(selectedTimecard)}
      />

      <TimecardDiscardConfirmationModal
        openCancelModal={Boolean(openCancelModal) && !openDeleteModal}
        onKeepEditing={() => setOpenCancelModal(false)}
        onDiscardEditing={() => {
          setOpenCancelModal(false);
          setOpenEditModal(false);
          setSelectedTimecard(undefined);
        }}
      />
    </div>
  );
};

export default DailyHoursTable;
