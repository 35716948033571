import firebase from 'firebase/compat/app';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import momentTz from 'moment-timezone';

export type millis = number;
const isMomentType = (maybe: moment.MomentInput): maybe is moment.Moment => {
  return (maybe as moment.Moment)?.toDate !== undefined;
};
type DateTimeType = moment.MomentInput | firebase.firestore.Timestamp;

const isFirebaseTimestampType = (maybe: DateTimeType): boolean =>
  (maybe as firebase.firestore.Timestamp)?.seconds !== undefined;

export const DefaultDateFormat = 'MM/DD/YYYY';
export const UTCFormat = 'x';

// provides a best effort to produce a moment object set to Midnight
// if UTC, provides UTC. If time is midnight locally, uses a local object.
// otherwise, tries to round to nearest midnight
export const enforceStartOfDay = (m: moment.MomentInput, fmt?: string): moment.Moment | null => {
  if (m == null) {
    return null;
  }
  if (moment.utc(m, fmt).diff(moment.utc(m, fmt).startOf('day')) === 0) {
    // timestamp is utc
    return moment.utc(m, fmt);
  } else if (moment(m, fmt).diff(moment(m, fmt).startOf('day')) === 0) {
    // timestamp is local
    return moment(m, fmt).utc().startOf('day');
  } else {
    // best guess - round to nearest midnight
    const halfDay = 12 * 60 * 60 * 1000;
    if (Math.abs(moment.utc(m, fmt).startOf('day').diff(moment.utc(m, fmt))) > halfDay) {
      // round up
      return moment.utc(m, fmt).add(1, 'days').startOf('day');
    } else {
      // round down
      return moment.utc(m, fmt).add(0, 'days').startOf('day');
    }
  }
};

// returns as Date object assuming utc
export const toDateObject = (date: DateTimeType, dateFormat = DefaultDateFormat): Date | null => {
  if (!date) return null;

  if (typeof date === 'number') return new Date(date);
  else if (typeof date === 'string') return enforceStartOfDay(date, dateFormat)?.toDate() ?? null;
  else if (isMomentType(date)) return date.toDate();
  else if (date instanceof Date) return date;
  else if (isFirebaseTimestampType(date)) return (date as firebase.firestore.Timestamp)?.toDate();
  else return null;
};

export const dateFormatter = (date: DateTimeType, format = DefaultDateFormat, shouldEnforceStartOfDay = true) => {
  if (!date) {
    return null;
  }
  const dateObj = toDateObject(date, format);

  return shouldEnforceStartOfDay
    ? enforceStartOfDay(dateObj)?.format(format ? format : DefaultDateFormat)
    : moment(dateObj)?.format(format ? format : DefaultDateFormat);
};

/**
 * Utility method to format DatePicker string value to UTC millis.
 * Can optionally handle a single argument moment object or
 * a date string with format pattern.
 *
 * @param {string | momentDate} dateVal Either a moment object or string value
 * @param {string} [dateFmt=DefaultDateFormat] Only used if 1st argument is a date string.
 * @return {millis} date as UTC millis
 * @return {null} if dateVal is null
 */
export const formatStringAsUtcMillisOrNull = (
  dateVal: string | moment.Moment,
  dateFmt: string = DefaultDateFormat
): millis | null => {
  if (_.isEmpty(dateVal)) {
    return null;
  }

  const momentDate = moment.isMoment(dateVal) ? enforceStartOfDay(dateVal) : enforceStartOfDay(dateVal, dateFmt);
  return momentDate?.valueOf() ?? null;
};

export const formatISOStringAsUtcDateOrNull = (dateVal: string | null | undefined): Date | null => {
  if (!dateVal) return null;

  const momentDate = enforceStartOfDay(dateVal);
  return momentDate?.toDate() ?? null;
};

/**
 * Converts UTC (ms) timestamp into a date string.
 *
 * @param {millis} utcMillis utc timestamp in milliseconds
 * @param {String} [optFmt=DefaultDateFormat] date format string acceptable to Moment
 * @return {String} Date string formatted according to optFmt
 * @return {null} null if null value provided
 */
export const formatUtcMillisAsString = (utcMillis: millis, optFmt: string = DefaultDateFormat): string | null => {
  if (utcMillis == null) {
    return null;
  }
  return enforceStartOfDay(`${utcMillis}`, UTCFormat)?.format(optFmt) ?? null;
};

/**
 * Utility method to format timestamp(ms) to date based on timezone.
 * Can optionally handle a single argument moment object or
 * a date string with format pattern.
 *
 * @param {string | momentDate} dateVal Either a moment object or string value
 * @param {string} [dateFmt=DefaultDateFormat] Only used if 1st argument is a date string.
 * @return {String} Date string formatted according to dateFmt
 * @return {null} if dateVal is null
 */
export const formatTimestampAsStringWithTimezone = (
  dateVal: millis,
  dateTz?: string,
  dateFmt: string = DefaultDateFormat
): string | null => {
  if (!moment(dateVal, 'x', true).isValid()) {
    return null;
  }

  const momentDate = dateTz ? momentTz.tz(dateVal, dateTz).format(dateFmt) : moment.utc(dateVal).format(dateFmt);

  return momentDate ?? null;
};

/**
 * @param {date} date - The date to convert.
 * @param {string} format - format string for date
 */
export const formatLocaleDate = (date: moment.MomentInput, format = '') => {
  if (date == null) {
    return null;
  }
  const dateObj = isMomentType(date) ? date.toDate() : date;
  return moment(dateObj)?.format(format ? format : DefaultDateFormat);
};

/**
 * Utility method to format DatePicker string value to locale millis.
 * Can optionally handle a single argument moment object or
 * a date string with format pattern.
 *
 * @param {string | momentDate} dateVal Either a moment object or string value
 * @param {string} [dateFmt=DefaultDateFormat] Only used if 1st argument is a date string.
 * @return {millis} date as locale millis
 * @return {null} if dateVal is null
 */
export const formatStringAsLocaleMillisOrNull = (dateVal: string | moment.Moment): millis | null => {
  if (_.isEmpty(dateVal)) {
    return null;
  }
  const momentDate = moment(dateVal);
  return momentDate?.valueOf() ?? null;
};

// Given a number of minutes, return a string of the format 'Xhr Ymin'
// e.g. 90 minutes => '1hr 30min'
export function formatHoursFromMinutes(minutes: number): string {
  if (minutes === undefined || minutes === null) return '-';
  const hours = Math.floor(minutes / 60);
  const minutesLeftover = minutes % 60;
  if (isNaN(hours) && isNaN(minutesLeftover)) {
    return '-';
  }
  return `${hours}hr ${minutesLeftover}min`;
}

export const getAgeFromBirthday = (birthday) => {
  const date = toDateObject(birthday);
  if (!date) return null;

  const totalMonths = moment().diff(date, 'months');
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  return { months, years, totalMonths };
};

// Trying to encapsulate moment logic in one place. (futile, I know).
export const isTodayOrBefore = (date: moment.MomentInput) => {
  if (!date) return false;

  const today = moment().startOf('day');
  const dateToCheck = moment(date).startOf('day');
  return dateToCheck.isSameOrBefore(today);
};

/*
export const toMondayMoment = (date: Date) => {
  const mondayMoment = moment(date).startOf('week');
  const day = date.getDay() || 7;
  if (day !== 1) date.setHours(-24 * (day - 1));
  return date;
}
*/
