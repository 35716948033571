import { FoodProgramQueryProvider, MenuPlanningEdit } from '@wonderschool/common-food-program-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCurrentEnvironment } from '../../../common/utils/environmentUtils';
import { getTitlePrefix } from '../../../config/env';
import { useAuthUser } from '../../../hooks/useUser';
import { useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

interface MenuPlanningUpdateRouteParams {
  date: string;
}

const MenuPlanningUpdate = () => {
  useSegmentPage(PAGE_NAMES.foodProgramMenuPlanningUpdate);

  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.menu.planning');
  const { goBack } = useRoutes();
  const { token } = useAuthUser();
  const { date } = useParams<MenuPlanningUpdateRouteParams>();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );

  if (!token) return null;

  const menuEventDate = dayjs(date || new Date().toISOString()).toDate();

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <MenuPlanningEdit menuEventDate={menuEventDate} onClickBack={goBack} />
    </FoodProgramQueryProvider>
  );
};
export default MenuPlanningUpdate;
