import classNames from 'classnames/bind';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import fStyle from '../../../Components/Billing/FinanceAtGlance/FinanceAtGlance.module.scss';
import { showSetupPayoutRibbon } from '../../../helpers/stripe';
import { useFinanceAtGlance } from '../../../hooks/useFinanceAtGlance';
import { useOrganization } from '../../../hooks/useOrganizations';
import { selectFirstLocation } from '../../DateTime/DateTime';
import { ShowErrors } from '../../Messages';
import SetupPayoutBanner from '../../SetupPayoutBanner';
import FinanceAtGlanceHeader from './FinanceAtGlanceHeader';
import TransactionSection from './TrasactionsSection';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

const fx = classNames.bind(fStyle);

function TransactionsAtGlance() {
  const [errorMessage, setErrorMessage] = useState(null);
  const organizationRef = useOrganization();
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const [selectedMonth, setSelectedMonth] = useState(moment().tz(timezone, true));
  const [isRefreshToggle, setIsRefreshToggle] = useState(false);

  const refreshHandler = () => {
    setIsRefreshToggle(!isRefreshToggle);
  };

  const { isLoadingTransactions, transactionData } = useFinanceAtGlance(selectedMonth, 'transactions', isRefreshToggle);

  return (
    <div className={fx('invoiceListWrapperFinanceAtGlance')} style={{ padding: ' 0 20px 10px ' }}>
      <div className={fx('mt-10')}>
        <FinanceAtGlanceHeader
          title="financeAtGlanceTransactionsHeader"
          selectedMonth={setSelectedMonth}
          refreshHandler={refreshHandler}
          isLoading={isLoadingTransactions}
        />

        {showSetupPayoutRibbon(organizationRef) ? (
          <div style={{ marginTop: '10px' }}>
            <SetupPayoutBanner onError={(error) => setErrorMessage(error)} />
            {errorMessage && <ShowErrors content={errorMessage} />}
          </div>
        ) : (
          <div className={fx('mt-10')}>
            <TransactionSection revenue={transactionData?.revenue} expenses={transactionData?.expense} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionsAtGlance;
