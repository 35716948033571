import { StaffDetail, StaffDetailProps } from '@wonderschool/ccms-ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getCurrentEnvironment } from '../common/utils/environmentUtils';
import { getTitlePrefix } from '../config/env';
import { useAuthUser } from '../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../navigation';

const StaffDetailsPage = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const { token } = useAuthUser();
  const { staffId } = useParams<Pick<StaffDetailProps, 'staffId'>>();
  const { gotoRouteByName } = useRoutes();

  const PageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('Staff Details - Wonderschool')}
      </title>
      <body />
    </Helmet>
  );

  if (!token) return null;
  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      <PageHead />
      <StaffDetail
        staffId={staffId}
        onDone={() => {
          gotoRouteByName(RouteNameEnum.NEWSTAFF);
        }}
      />
    </FoodProgramQueryProvider>
  );
};

export default StaffDetailsPage;
